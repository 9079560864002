import { toast } from "react-toastify";
import { BackendPath, INIT_PAYMENT_ERROR } from "../constants/api";
import { useCart } from "../contexts/CartContext";
import { useText } from "../contexts/TextContext";
import ResponseInitPayment from "../models/api/ResponseInitPayment";
import { Cart } from "../models/Cart";
import config from '../utils/config'
import { useAuth } from "../contexts/AuthContext";
import { useLastOrder } from "../contexts/LastOrderContext";
import { PAYMENT_TYPES } from "../utils/shared/constants";


interface BackendHook {

    initPayment: (cart: Cart, restaurantSysid: string, type: PAYMENT_TYPES) => Promise<ResponseInitPayment>,
    handleInitPaymentError: (error: any) => void,
    paymentCallback: (orderId: string, paymentType: string, restaurantSysid: string) => Promise<void>
    refund: (orderId: string, restaurantId: string) => Promise<void>,
    prepareReceiptCopy: (orderId: string, restaurantId: string, type: 'receipt' | 'refund') => Promise<void>,
    physicalCardRegisterTerminal: (restaurantId: string, terminalName: string) => Promise<string>,

    getQR: (token: string) => Promise<Blob>,
    getOfferText: (restaurantId: string, dealId: string) => Promise<string>,

};


export function useBackend(): BackendHook {
    const text = useText();
    const { cart, clearCart } = useCart();
    const { currentUser } = useAuth();
    const { setLastOrderId } = useLastOrder();

    const initPayment = async (cart: Cart, restaurantSysid: string, type: PAYMENT_TYPES): Promise<ResponseInitPayment> => {
        const url = `${config.firebaseFunctionEndpoint}/${BackendPath.INIT_PAYMENT}?restaurant=${restaurantSysid}&type=${type}`;

        const body = JSON.stringify({ cart });

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body
        });

        const responseBody = await response.json();
        if (response.status === 400 && responseBody.reason) {
            throw Error(responseBody.reason)
        } else if (response.status !== 200) {
            throw Error('Non-200 response');
        }

        const responceData: ResponseInitPayment = responseBody;

        setLastOrderId(responceData.orderId);

        return responceData
    }

    const CUSTOMER_ERRORS: Record<string, string> = {
        [INIT_PAYMENT_ERROR.ERROR_PRODUCT_NOT_FOUND]: text.errorInvalidProduct,
    }
    const handleInitPaymentError = (error: any) => {
        console.error('Failed to init payment', error);
        if (CUSTOMER_ERRORS[error.message]) {
            toast.error(CUSTOMER_ERRORS[error.message]);
            if (error.message === INIT_PAYMENT_ERROR.ERROR_PRODUCT_NOT_FOUND) {
                clearCart(cart.id)
            }
        } else {
            toast.error(text.errorDefault);
        }
    }


    const getQR = async (token: string): Promise<Blob> => {
        const url = `${config.firebaseFunctionEndpoint}/${BackendPath.GET_SWISH_QR}`;

        const data = {
            format: "png",
            size: 300,
            token,
            transparent: true,
        };

        const body = JSON.stringify(data);

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body
        });

        if (response.status !== 200) {
            throw Error('Non-200 response');
        }

        return await response.blob();
    }

    const getOfferText = async (restaurantId: string, dealId: string): Promise<string> => {
        const url = `${config.firebaseFunctionEndpoint}/${BackendPath.GET_OFFER_TEXT}`;

        // fetch the offer text
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                restaurantId,
                dealId,
            }),
            headers: {
                'content-type': 'application/json'
            },
        });

        if (response.status !== 200) {
            return '';
        }

        const responseBody = await response.json();
        return responseBody.text || '';

    };

    return {
        initPayment,
        handleInitPaymentError,

        paymentCallback: async (orderId: string, paymentType: string, restaurantSysid: string) => {
            const params = `order=${orderId}&restaurant=${restaurantSysid}&type=${paymentType}`;
            const url = `${config.firebaseFunctionEndpoint}/${BackendPath.PAYMENT_CALLBACK}?${params}`;

            await fetch(url);
        },
        refund: async (orderId: string, restaurantId: string) => {
            const body = JSON.stringify({
                orderId,
                restaurantId,
            });
            const result = await fetch(`${config.firebaseFunctionEndpoint}/${BackendPath.REFUND}`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'authorization': 'Bearer ' + currentUser?.token,
                },
                body
            });
            if (result.status !== 200) {
                throw Error(`Failed with ${result.status}: ${await result.text()} `);
            }
        },
        prepareReceiptCopy: async (orderId: string, restaurantId: string, type: 'receipt' | 'refund') => {
            const body = JSON.stringify({
                orderId,
                restaurantId,
                type,
            });
            const result = await fetch(`${config.firebaseFunctionEndpoint}/${BackendPath.PREPARE_RECEIPT_COPY}`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'authorization': 'Bearer ' + currentUser?.token,
                },
                body
            });
            if (result.status !== 200) {
                throw Error(`Failed with ${result.status}: ${await result.text()} `);
            }
        },
        physicalCardRegisterTerminal: async (restaurantId: string, terminalName: string) => {
            const body = JSON.stringify({
                restaurantId,
                terminalName,
            });
            const result = await fetch(`${config.firebaseFunctionEndpoint}/${BackendPath.PHYSICAL_CARD_REGISTER_TERMINAL}`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'authorization': 'Bearer ' + currentUser?.token,
                },
                body
            });
            if (result.status !== 200) {
                throw Error(`Failed with ${result.status}: ${await result.text()} `);
            }

            const response = await result.json();
            return response.payload;
        },

        getQR,
        getOfferText,
    }
}