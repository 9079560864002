import React from 'react'
import { useRestaurant } from '../contexts/RestaurantContext'

export default function PrivacyPage() {
    const restaurant = useRestaurant();
    let officialName = 'SliceSize AB'
    let name = 'SliceSize'
    if (restaurant.name) {
        officialName = restaurant.officialName || restaurant.name;
        name = restaurant.name;
    }
    const email = restaurant.email || 'Johan.rudby@icloud.com';
    const address = restaurant.officialAddress || restaurant.address || '';

    return (
        <div className='container text-primary'>
            <h4>Integritetspolicy</h4>
            <p>
                Integritetspolicyn innehåller viktig information om hur vi som personuppgiftsansvarig behandlar våra besökares
                och kunders personuppgifter enligt den allmänna dataskyddsförordningen (GDPR).
                Denna policy gäller i situationer där {officialName} ({name}) behandlar personlig information som samlats in från webbplatsen och andra digitala kanaler där vi finns.
            </p>

            <p>
                Genom att använda vår webbplats och/eller andra digitala kanaler där du aktivt skickar information till oss, godkänner du att din personliga information behandlas i enlighet med denna integritetspolicy.
            </p>
            <p>
                Integritetspolicyn gäller från och med 2022-07-01 och ersätter all tidigare information gällande vår hantering av personlig data.
            </p>

            <h4>Personuppgifter</h4>
            <p>
                Personuppgifter är information som möjliggör identifiering av en fysisk person. En identifierbar fysisk person är en person som indirekt eller direkt kan identifieras via namn, personnummer, platsdata, IP-adress eller annan faktor som möjliggör identifiering.
            </p>

            <h4>Personuppgifter som du lämnar till oss</h4>
            <p>
                Som vår kund kan du i samband med att du lägger en order komma att lämna personlig information till oss. Detta kan exempelvis gälla namn och kontaktuppgifter.
            </p>

            <h4>Personuppgifter som vi har samlat in från dig eller en tredje part</h4>
            <p>
                Ingen personlig information samlas utan ditt medgivande. Du behöver aktivt göra en order alternativt kontakta oss via exempelvis ett formulär på vår webbplats eller i andra digitala kanaler.
            </p>

            <h4>Syfte med behandling av dina personuppgifter</h4>
            <p>
                För att hantera dina ordrar och fullfölja vårt åtagande gentemot dig som kund i samband med att du lägger en order eller bokar en tjänst.
            </p>
            <p>
                I marknadsföringssyfte genom att använda cookies för att ge personlig service och förbättra vår kundservice.
            </p>

            <h4>Mottagare av dina personuppgifter</h4>
            <p>
                Vi överför inte personuppgifter till tredje part med undantag för betrodda tredje parter som hjälper oss driva vår verksamhet, hanterar webbplatsen, leverera varor du beställer från oss eller tillhandahåller service, om dessa parter är överens om att hålla informationen konfidentiell och inte använda den för andra ändamål än deras åtaganden gentemot oss.
            </p>

            <h4>Hur länge sparar vi dina personuppgifter</h4>
            <p>
                Vi sparar dina personuppgifter så länge syftet kvarstår som de ursprungligen samlades in för. Syftet till insamling tydliggörs i samband med ditt samtycke.
            </p>

            <h4>Känsliga personuppgifter</h4>
            <p>
                Vi samlar inga känsliga personuppgifter om dig. Eventuella kortuppgifter samlas ej hos {officialName}.
            </p>

            <h4>Dina Rättigheter</h4>
            <ul>
                <li>
                    Du har rätt att återkalla ett lämnat samtycke.
                </li>
                <li>
                    Du har rätt att begära information om vilka personuppgifter om dig som vi sparar.
                </li>
                <li>
                    Du har möjlighet att göra detta kostnadsfritt en gång om året.
                </li>
                <li>
                    Du kan också begära ändringar i felaktiga personuppgifter vi har om dig samt begära att vi tar bort den personliga information vi har om dig.
                </li>
                <li>
                    Du har rätt få dina personliga uppgifter som vi sparar raderade.
                </li>
                <li>
                    Du har rätt att invända mot hur vi använder dina personuppgifter.
                </li>
                <li>
                    Du har rätt att begära att vår behandling av dina personuppgifter begränsas.
                </li>
                <li>
                    Du har rätt att föra klagomål gällande vår behandling av dina personuppgifter till Datainspektionen eller annan myndighet.
                </li>
            </ul>

            <h4>Tredjepart</h4>
            <p>
                {officialName} har inga länkar till tredje parts produkter. Samtliga produkter på webbplatsen säljs direkt från {officialName}.
            </p>

            <h4>Ändringar av integritetspolicyn</h4>
            <p>
                {officialName} förbehåller sig rätten att göra ändringar i denna policy.
            </p>
            <p>
                Senast uppdaterad: 2022-06-30
            </p>

            <h4>Kontakt</h4>
            <p>
                Om du har frågor om vår integritetspolicy eller vår behandling av personuppgifter är du välkommen att kontakta oss på {email}
            </p>
            <p>alternativt via post.</p>
            <p>
                {officialName} <br />
                {address}
            </p>
        </div>
    )
}
