import config from './config';


const firebaseDevConfig = {
    apiKey: "AIzaSyAvuPU4hUaNKTlEeb98dzp8_NmVBVmu2UE",
    authDomain: "johan-friends-dev.firebaseapp.com",
    projectId: "johan-friends-dev",
    storageBucket: "johan-friends-dev.appspot.com",
    messagingSenderId: "784244381293",
    appId: "1:784244381293:web:2aa8612295595f91d95164",
    measurementId: "G-SM1LN62RM3"
};
const firebaseDevCloudMessagingKey = 'BG6vepLBnklJgXH7EkHhbR4pEHMm2efBEZOYE3SKn3Cn74xFbH81XDJfNlmPLdaqARGe9i-7cRdhJBg8Ob4fWuk';

const firebaseProdConfig = {
    apiKey: "AIzaSyBlQZVXg4JOca7_KjxzBtxnENalEB5XiFw",
    authDomain: "johan-friends.firebaseapp.com",
    projectId: "johan-friends",
    storageBucket: "johan-friends.appspot.com",
    messagingSenderId: "363179700047",
    appId: "1:363179700047:web:7577d81e7441404af59f3a",
    measurementId: "G-DPKEJL4KWF"
};
const firebaseProdCloudMessagingKey = 'BCFWGgfYPeMZYziFmJjSQ4jUwJJ05IOL2IHBxuE0l1kqQIMrlwC_VVAGTTAYW26rBh5wCXFVpnpSGaE7HiO-NyY';

export const firebaseConfig = config.isProduction ? firebaseProdConfig : firebaseDevConfig;
export const firebaseCloudMessagingKey = config.isProduction ? firebaseProdCloudMessagingKey : firebaseDevCloudMessagingKey;