import React from 'react'
import Product, { ProductForm } from '../models/Product'
import { EggSVG } from '../assets/svgs';

interface Props {
    product: Product | ProductForm
    type: 'preview' | 'detail'
}

export default function ProductImg({ product, type }: Props) {
    return (
        <div className={`product-image ${type}`}>
            {product.pictureUrl
                ? <img src={product.pictureUrl} alt={product.name} className="mw-100 mh-100" />
                : EggSVG
            }
        </div>
    )
}
