import { Navigate } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"
import { useRestaurant } from "../contexts/RestaurantContext"
import NotAuthorizedPage from "../pages/NotAuthorizedPage";
import { getRestaurantPath } from "../utils/restaurant";

type Props = {
    type?: 'customer' | 'superAdmin',
    children: JSX.Element
}

export default function RequireAuth({ type, children }: Props): JSX.Element {
    const { currentUser } = useAuth()
    const restaurant = useRestaurant();

    let isAuthorized = currentUser && (currentUser.isSuperAdmin || currentUser.staff === restaurant.id)
    if (type === 'superAdmin') {
        isAuthorized = !!currentUser?.isSuperAdmin
    }
    if (type === 'customer') {
        isAuthorized = !!currentUser
    }

    if (!isAuthorized) {
        if (type === 'customer') {
            return <Navigate to={getRestaurantPath(restaurant, '/')} />
        }

        return <NotAuthorizedPage />
    }


    return children
}