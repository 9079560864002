export default interface RestaurantStyles {
    primary: string,
    bgPrimary: string,
    bgSecondary: string,
}

export const DEFAULT_STYLES: RestaurantStyles = {
    primary: '#242B7C',
    bgPrimary: '#3a414a',
    bgSecondary: '#f8f9fa',
}
