import React, { useState } from 'react'
import { useText } from '../../contexts/TextContext';
import { Cart, CartItem } from '../../models/Cart'
import { Order } from '../../models/Order'
import { sumCart } from '../../utils/shared/cart';
import { printAmount } from '../../utils/shared/converters';

interface Props {
    order: Cart | Order,
    changeQuantity?: (item: CartItem, newQuantity: number) => void
}

export default function LineItems(props: Props) {
    const { order, changeQuantity } = props;
    const text = useText();
    const { sum, discount, tip } = sumCart(order);
    const [openedLine, setOpenedLine] = useState(-1);

    const handleLineClick = (ix: number) => {
        setOpenedLine(openedLine === ix ? -1 : ix);
    }

    return (<div className='d-flex flex-column gap'>
        <div className='d-flex flex-column gap mb-3'>

            {order.items.map((item, ix) => {
                const lineTotal = item.itemPrice * item.quantity;
                const canBeLowered = item.quantity > 1;
                const isLineSelected = openedLine === ix
                const isSomethingElseSelected = !isLineSelected && openedLine >= 0;

                return <div key={ix} className="animate" style={{
                    opacity: isSomethingElseSelected ? 0.5 : 1
                }}>
                    <div className='row pointer' onClick={() => handleLineClick(ix)}>

                        <div className='col-6   '>
                            <div>{item.itemName}</div>
                            <div className='text-small text-muted'>{text.productCost}: {printAmount(item.itemPrice)}</div>
                        </div>

                        <div className='col-1'>

                            <button className='btn me-1 m-0 p-0 bi bi-sliders' />
                        </div>
                        <div className='col-2' >
                            <span className='bold'>{item.quantity}</span>
                            <span className='ms-1 text-muted'>
                                <i className='bi bi-plus-slash-minus' />
                            </span>
                        </div>

                        <div className='col-3 text-end'>{printAmount(lineTotal)}</div>
                    </div>
                    <div className='animate-open' style={{ maxHeight: isLineSelected ? 50 : undefined }}>
                        <div className='mt-1 d-flex justify-content-between'>
                            <div>
                                {text.changeCount}
                            </div>
                            <div>

                                {changeQuantity && <button className={`btn btn-sm ${canBeLowered ? 'btn-outline-primary' : 'btn-outline-danger'}`}
                                    onClick={() => changeQuantity(item, item.quantity - 1)}>
                                    <i className={`bi ${canBeLowered ? 'bi-dash' : 'bi-trash'}`} />
                                </button>}
                                {changeQuantity && <button className='btn btn-sm btn-outline-primary'
                                    onClick={() => changeQuantity(item, item.quantity + 1)}>
                                    <i className='bi bi-plus' />
                                </button>}
                            </div>
                        </div>
                    </div>
                </div>
            })}
        </div>
        {!!discount && <div className="d-flex justify-content-between ">
            <div >{text.discount}</div>
            <div >{printAmount(-discount)}</div>
        </div>}
        {!!order?.tip && <div className="d-flex justify-content-between ">
            <div >{`${text.leaveTip} (${order.tip} %)`}</div>
            <div >{printAmount(tip)}</div>
        </div>}
        <div className="d-flex justify-content-between ">
            <div >{text.sum}</div>
            <div className='bold' >{printAmount(sum)}</div>
        </div>
    </div>
    )
}
