export enum FEATURE {
    ESTIMATE_TYPE = 'estimateType',
    CHECKOUT_CUSTOM_TEXT = 'checkoutText',
    CHECKOUT_CUSTOM_TEXT_VALUE = 'checkoutTextValue',
    ENABLED_PAYMENTS = 'enabledPayments',
    ENABLED_CONTROL_UNIT = 'enabledControlUnit',
}

export enum CHECKOUT_CUSTOM_TEXT {
    NONE = 'none',
    TEXT_ONLY = 'textOnly',
    TEXT_WITH_CONFIRM = 'textConfirm',
}

export enum ESTIMATE_TYPE {
    QUEUE = 'queue',
    TIME = 'time',
}
