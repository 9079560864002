import { onSnapshot } from '@firebase/firestore';
import { doc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import FailedPaymentAlert from '../../components/FailedPaymentAlert';
import Loading from '../../components/Loading';
import Menu from '../../components/customer/Menu'
import ConfirmOrderStatus from '../../components/customer/ConfirmOrderStatus';
import { useDB } from '../../contexts/FirebaseContext';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { Order, OrderState } from '../../models/Order';
import { fromFirebaseDoc } from '../../utils/shared/firebase';
import { useCart } from '../../contexts/CartContext';
import CompanyFooter from '../../components/customer/CompanyFooter';
import { useBackend } from '../../hooks/useBackend';

const FORCE_CALLBACK_DELAY = 1000 * 5;

export default function ConfirmPage() {
    const [order, setOrder] = useState<Order | null>(null);
    const { orderId } = useParams<{ orderId: string }>();
    const db = useDB();
    const { clearCart } = useCart();
    const restaurant = useRestaurant();
    const { paymentCallback } = useBackend();

    const orderState = order?.state || OrderState.NEW;
    const isLoading = orderState === OrderState.NEW;
    const isAccepted = orderState === OrderState.PAID || orderState === OrderState.DONE || orderState === OrderState.IN_PROGRESS;

    useEffect(() => {
        if (db && orderId && restaurant.id) {
            return onSnapshot(doc(db, "restaurants", restaurant.id, "orders", orderId), (doc) => {
                const newOrder = fromFirebaseDoc<Order>(doc)
                setOrder(newOrder);
            });
        }
    }, [db, orderId, restaurant.id]);

    useEffect(() => {
        if (isAccepted && order?.cartId) {
            clearCart(order.cartId);
        }
    }, [order?.cartId, clearCart, isAccepted])

    useEffect(() => {
        if (!isLoading || !order) {
            return;
        }

        const timeout = setTimeout(() => {
            console.log('still loading lets ask for check');
            paymentCallback(order.id || '', order.paymentType || '', restaurant.sysid);

        }, FORCE_CALLBACK_DELAY);
        return () => clearTimeout(timeout);
    }, [isLoading, order, paymentCallback, restaurant.sysid])


    return (
        <div className='streach'>
            <Menu hideLastOrder />
            <div className='center flex-column'>
                {isLoading && <Loading />}
                {orderState === OrderState.FAILED && <FailedPaymentAlert orderId={order?.id || ''} paymentType={order?.paymentType || ''} />}
                {isAccepted && <ConfirmOrderStatus order={order!} />}
            </div>

            <CompanyFooter />
        </div>
    )
}
