import React, { useState } from 'react'
import { useText } from '../contexts/TextContext'
import { useBackend } from '../hooks/useBackend';
import { useRestaurant } from '../contexts/RestaurantContext';


interface Props {
    orderId: string
    paymentType: string
}
export default function FailedPaymentAlert(props: Props) {
    const text = useText();
    const [processing, setProcessing] = useState(false);
    const { paymentCallback } = useBackend();
    const restaurant = useRestaurant();


    const retryPayment = async () => {
        setProcessing(true);

        await paymentCallback(props.orderId, props.paymentType, restaurant.sysid)

        setProcessing(false);
    }

    return (
        <div className="alert alert-danger center flex-column">
            <p> {text.paymentFailed} </p>
            <p> {text.tryAgain} </p>

            <button className='btn btn-primary' onClick={retryPayment}>
                {processing && <span className="spinner-border spinner-border-sm me-2" />}
                {text.retry}</button>
        </div>
    )
}
