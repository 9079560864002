import React from 'react'

interface Props {
    open: boolean
    onClick: () => void
}

export default function OpenOnTheEdge(props: Props) {
    return (
        <div className='open-at-edge'>
            <button className={`bi bi-chevron-compact-${props.open ? 'up' : 'down'}`} onClick={() => props.onClick()} />
        </div>
    )
}
