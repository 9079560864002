import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import useDeals from '../hooks/useDeals';
import Menu from '../components/customer/Menu';
import { useText } from '../contexts/TextContext';
import RestaurantLink from '../components/RestaurantLink';
import { CUSTOMER_NAV } from '../constants/routes';
import { AppearingText } from '../components/customer/Backstory';
import { useBackend } from '../hooks/useBackend';
import { toast } from 'react-toastify';
import { useRestaurant } from '../contexts/RestaurantContext';
import { useCart } from '../contexts/CartContext';
import { getRestaurantPath } from '../utils/restaurant';

export default function OfferPage() {
    const { code } = useParams<{ code: string }>();
    const deals = useDeals();
    const [description, setDescription] = React.useState('');
    const { getOfferText } = useBackend();
    const text = useText();
    const restaurant = useRestaurant();
    const { addDeal } = useCart();
    const navigate = useNavigate();

    const existingDeal = deals.find(deal => deal.code?.trim().toLowerCase() === code?.trim().toLowerCase());

    useEffect(() => {
        if (existingDeal && restaurant.id) {
            getOfferText(restaurant.id, existingDeal.id)
                .then(setDescription)
                .catch(e => {
                    console.error(e);
                    toast.error(text.errorDefault)
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [existingDeal, restaurant.id]);

    const claimOffer = () => {
        if (existingDeal) {
            addDeal(existingDeal);
            navigate(getRestaurantPath(restaurant, '/'));
        }

    }


    return (
        <div className='streach'>
            <Menu noBack={true} />

            <div className='container center pt-5'>

                {existingDeal
                    ? <div className='center flex-column'>
                        {description
                            ? <AppearingText className='text-primary' text={description} />
                            : <div className="spinner-border text-primary" role="status" />
                        }
                        <div className="text-muted">{'<<' + text.offerTextGeneratedByAIExcuseInperfections + '>>'}</div>

                        <button className="btn w-100 btn-primary mt-5" onClick={claimOffer}>{text.claimOffer}</button>

                    </div>
                    : <div className='center flex-column'>
                        <div className='alert alert-info'>
                            {text.offerExpired}
                        </div>
                        <RestaurantLink to={`/${CUSTOMER_NAV.PRODUCTS}`} className='mt-5'>
                            <button className="btn btn-light">{text.products}</button>
                        </RestaurantLink>
                    </div>
                }
            </div>
        </div>
    )
}
