import React, { useEffect, useRef, useState } from 'react'
import { useText } from '../../contexts/TextContext';
import LoginButton from './LoginButton'
import mailLogo from '../../assets/mail_logo.png';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { useFirebaseAuth } from '../../contexts/FirebaseContext';
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/AuthContext';
import { addQueryParam } from '../../hooks/useQueryParams';
import { QUERY_PARAMS } from '../../utils/shared/constants';

export default function LoginEmailLink() {
    const text = useText();
    const [state, setState] = useState<'start' | 'input' | 'sent'>('start');
    const [email, setEmail] = useState('');
    const emailInput = useRef<HTMLInputElement>(null);
    const auth = useFirebaseAuth();
    const [sendingEmail, setSendingEmail] = useState(false);
    const { setMagicLinkEmail } = useAuth();

    const sendEmail = async (e: React.FormEvent) => {
        e.preventDefault();

        setSendingEmail(true);

        try {
            const params = addQueryParam(QUERY_PARAMS.EMAIL_SIGNUP_FINISH, 'true');
            const url = window.location.origin + window.location.pathname + '?' + params;
            await sendSignInLinkToEmail(auth, email, {
                url,
                handleCodeInApp: true
            });
            setMagicLinkEmail(email);
            setState('sent');
        } catch (e) {
            console.error('auth', e);
            toast.error(text.errorDefault)
        }

        setSendingEmail(false);
    }

    useEffect(() => {
        if (state === 'input' && emailInput.current) {
            emailInput.current.focus();
        }
    }, [state, emailInput])


    if (state === 'start') {
        return <LoginButton handler={() => setState('input')} logo={mailLogo} text={text.login_withEmail} />
    }

    if (state === 'input') {
        return <form className='center my-2 border rounded' onSubmit={sendEmail}>
            <input className="inline"
                ref={emailInput}
                type='email'
                placeholder={text.login_email}
                value={email} onChange={e => setEmail(e.target.value)} />
            <button className='btn text-primary' disabled={!email.trim() || sendingEmail}>
                {sendingEmail
                    ? <i className="spinner-grow spinner-grow-sm" />
                    : <i className="bi bi-send-fill " />
                }
            </button>
        </form>
    }

    return <div className='border rounded my-2 py-2 center text-success'>
        <i className='bi bi-check me-2' /> {text.login_magicLinkSent}
    </div>
}
