import React from 'react'

interface Props {
    logo: string;
    handler: () => void;
    text: string;
}

export default function LoginButton(props: Props) {
    return (
        <button className='btn border my-2 py-2 w-100 d-flex row mx-0' type="button" onClick={props.handler}>
            <div className='col-2'>
                <img src={props.logo} alt="Google" className='me-2' width="24" height="24" />
            </div>
            <div className='col-10'>
                {props.text}
            </div>
        </button>
    )
}
