import React, { useEffect } from "react";
interface Props {
    text?: string
    className?: string
    style?: React.CSSProperties
}

const SPEED = 50;
export function AppearingText({ text, className, style }: Props) {
    const [carrigeReturn, setCarrigeReturn] = React.useState(0);
    const [currentInterval, setCurrentInterval] = React.useState<NodeJS.Timer | null>(null);

    useEffect(() => {
        if (text) {
            const interval = setInterval(() => {
                setCarrigeReturn(cr => cr + 1);
            }, SPEED);
            setCurrentInterval(interval);
            return () => clearInterval(interval);
        }
    }, [text])

    useEffect(() => {

        if (text && currentInterval && carrigeReturn >= text.length) {
            clearInterval(currentInterval);
            setCurrentInterval(null);
        }
    }, [carrigeReturn, currentInterval, text])

    if (!text) {
        return <></>;
    }

    const visibleText = text.substring(0, carrigeReturn);

    return <div className={className}>
        <p style={{ textAlign: 'start' }}>{visibleText}</p>
    </div>;
}
