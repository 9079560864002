import { parseAmount, printAmount } from "../utils/shared/converters";

export default interface ProductOptionChoice {
    id: string,
    name: string,
    disabled: boolean,
    default: boolean,
    priceAdjustment: number
    color?: string,
};

export interface ProductOptionChoiceForm {
    id: string,
    name: string,
    disabled: boolean,
    default: boolean,
    priceAdjustment: string
    color: string,
}

export const productOptionChoiceToForm = (productOptionChoice: ProductOptionChoice): ProductOptionChoiceForm => {
    return {
        id: productOptionChoice.id,
        name: productOptionChoice.name,
        disabled: productOptionChoice.disabled,
        default: productOptionChoice.default,
        priceAdjustment: printAmount(productOptionChoice.priceAdjustment, { onlyAmount: true }),
        color: productOptionChoice.color || '',
    };
};

export const productOptionChoiceFromForm = (productOptionChoice: ProductOptionChoiceForm): ProductOptionChoice => {
    return {
        id: productOptionChoice.id,
        name: productOptionChoice.name,
        disabled: productOptionChoice.disabled || false,
        default: productOptionChoice.default || false,
        priceAdjustment: parseAmount(productOptionChoice.priceAdjustment),
        color: productOptionChoice.color.trim(),
    };
};
