import { Modal } from 'react-bootstrap';
import { Days } from '../../constants/days';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { useText } from '../../contexts/TextContext';

interface Props {
    show: boolean;
    onHide: () => void;
}


export default function OpeningHoursModal(props: Props) {
    const text = useText();
    const restaurant = useRestaurant();


    const printHours = (id: number) => {
        const segments = restaurant.openingHours?.[id];

        if (segments?.length) {
            return segments
                .map(segment => `${segment.start} - ${segment.end}`)
                .join('  ');
        }
        return '-'
    }

    return (
        <Modal show={props.show} onHide={props.onHide} className="modal fade">
            <Modal.Header closeButton>
                <Modal.Title>{text.openingHours}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="center">
                    <div className='d-flex flex-column' >

                        {Days.map(day => <div key={day.id} className="row">
                            <div className="col-2">{day.name.substr(0, 3)}:</div>
                            <div className="col-10 center">{printHours(day.id)}</div>
                        </div>)}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
