import React from 'react'
import StaffMenu from './StaffMenu'

interface Props {
    children: React.ReactNode | React.ReactNode[]
    noContainer?: boolean
    className?: string
}
export default function Layout(props: Props) {
    return (
        <div className="h-100 d-flex flex-column">
            <StaffMenu />
            <div className={`text-primary mt-3 ${props.noContainer ? '' : 'container'} ${props.className}`}>
                {props.children}
            </div>
        </div>
    )
}
