import React, { useContext, useState } from "react"
import { Language, Settings } from "../models/Settings";


const SETTINGS_KEY = 'settings';
const DEFAULT_LANGUAGE: Language = 'english';

interface SettingsContext {
    settings: Settings,
    updateSettings: (newSettings: Settings) => void
};

const settingsContext = React.createContext<SettingsContext>({
    settings: { language: DEFAULT_LANGUAGE },
    updateSettings: () => { }
});

export function useSettings() {
    return useContext(settingsContext)
}



const persistSettings = (settings: Settings) => {
    window.localStorage.setItem(SETTINGS_KEY, JSON.stringify(settings));
}

const getOrInitSettings = (): Settings => {
    const settingsString = window.localStorage.getItem(SETTINGS_KEY);


    if (settingsString) {
        const settings: Settings = JSON.parse(settingsString);
        return settings;
    }

    const newSettings: Settings = {
        language: DEFAULT_LANGUAGE,
    }
    persistSettings(newSettings);

    return getOrInitSettings();
}

export default function SettingsProvider({ children }: any) {
    const [settings, setSettings] = useState<Settings>(getOrInitSettings());

    const updateSettings = (newSettings: Settings) => {
        persistSettings(newSettings);
        setSettings(newSettings);
    }


    if (!settings) {
        return <></>
    }

    return (
        <settingsContext.Provider value={{
            settings,
            updateSettings
        }} >
            {children}
        </settingsContext.Provider>
    )
}
