import { useAuth } from "../contexts/AuthContext";
import { useText } from "../contexts/TextContext";
import Menu from "../components/customer/Menu";
import { useState } from "react";
import LoginModal from "../components/modals/LoginModal";

const NotAuthorizedPage = () => {
    const text = useText();
    const { currentUser, logout } = useAuth();
    const [showLogin, setShowLogin] = useState(false);

    const swithUser = async () => {
        await logout();
        setShowLogin(true);
    }

    return (
        <div className='streach'>
            <Menu />
            <div className="container center">
                <div className="center flex-column">

                    <h2>{text.notAuthorized}</h2>
                    <p>{text.notAuthorizedDescription}</p>

                    {currentUser
                        ? <button className="btn btn-light" onClick={swithUser}>{text.switchUser}</button>
                        : <button className="btn btn-light" onClick={() => setShowLogin(true)}>{text.login}</button>
                    }
                </div>
            </div>

            <LoginModal show={showLogin} onHide={() => setShowLogin(false)} />


        </div >
    );
}

export default NotAuthorizedPage;