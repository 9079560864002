import { query, collection, limit, onSnapshot, orderBy } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { STAFF } from '../constants/routes';
import { useDB } from '../contexts/FirebaseContext';
import Restaurant from '../models/Restaurant';
import { Order } from '../models/Order';
import { fromFirebaseDocs } from '../utils/shared/firebase';

export default function SuperAdmin() {
    const [restaurants, setRestaurants] = useState<Restaurant[]>([])
    const [orderCounts, setOrderCounts] = useState<Record<string, number>>({})
    const db = useDB();

    useEffect(() => {
        const queryObject = query(collection(db, 'restaurants'));
        return onSnapshot(queryObject, (querySnapshot) => {
            const restaurants = fromFirebaseDocs<Restaurant>(querySnapshot.docs);
            setRestaurants(restaurants);
        });
    }, [db])

    useEffect(() => {
        for (const restaurant of restaurants) {
            const queryObject = query(collection(db, `restaurants/${restaurant.id}/orders`),
                orderBy('publicId', 'desc'),
                limit(1));
            onSnapshot(queryObject, (querySnapshot) => {
                const orders = fromFirebaseDocs<Order>(querySnapshot.docs);
                let newNumber = 0;
                if (orders.length) {
                    newNumber = orders[0].publicId || 0;
                }
                setOrderCounts(current => ({ ...current, [restaurant.id]: newNumber }));
            });
        }

    }, [db, restaurants])

    return <div className='container text-primary'>

        <div className='pt-4'>
            {restaurants.map(restaurant => <div key={restaurant.id} className='row'>
                <div className='col'>{restaurant.name}</div>
                <div className='col'><Link to={`/${restaurant.sysid}`}>Shop</Link></div>
                <div className='col'><Link to={`/${restaurant.sysid}/${STAFF}`}>Staff</Link></div>
                <div className='col'>{`Orders: ${orderCounts[restaurant.id] || 0}`}</div>

            </div>)}
        </div>
    </div>;
}
