import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { useRestaurant } from '../contexts/RestaurantContext';
import { getRestaurantPath } from '../utils/restaurant';

export default function RestaurantLink(props: LinkProps) {
    const restaurant = useRestaurant();

    const to = getRestaurantPath(restaurant, props.to + '');

    return <Link {...props} to={to} />;
}
