import { useCart } from '../../contexts/CartContext';
import { CartItem } from '../../models/Cart';
import LineItems from './LineItems';


export default function CheckoutCart() {
    const { cart, updateItem } = useCart();

    const changeQuantity = (item: CartItem, newQuantity: number) => {
        const newItem: CartItem = {
            ...item,
            quantity: newQuantity
        }
        updateItem(newItem);
    }

    return <LineItems order={cart} changeQuantity={changeQuantity} />
}
