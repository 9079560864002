import React from 'react'
import Menu from '../../components/customer/Menu'
import NeonText from '../../components/customer/NeonText';
import StampCards from '../../components/customer/StampCards';
import { useText } from '../../contexts/TextContext'
import { StravaConnect } from '../../components/customer/StravaConnect';

export default function MyBenefits() {
    const text = useText();
    return (
        <div className='streach'>
            <Menu />
            <div className='container text-align-left text-primary'>
                <p>{text.myBenefitsDescription1}</p>

                <StampCards />

                <StravaConnect />

                <h2 className='mt-3'>{text.refferalProgram}</h2>
                <NeonText text={text.comingSoon} />
            </div>
        </div>
    )
}
