import { useRestaurant } from "../contexts/RestaurantContext";
import { todayAtTime } from "../utils/shared/converters";

interface OpeningHoursState {
    isOpen: boolean;
}

const useOpenState = (): OpeningHoursState => {
    const restaurant = useRestaurant();

    let isOpen = false;
    const today = new Date();
    const day = today.getDay();
    const openingHoursToday = restaurant.openingHours?.[day] || [];

    if (openingHoursToday.some(section => {
        const startTime = todayAtTime(section.start);
        const endTime = todayAtTime(section.end);

        return startTime <= today && today <= endTime;
    })) {
        isOpen = true;
    }

    return {
        isOpen,
    }

}

export default useOpenState;