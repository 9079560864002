import React from 'react'

interface Props {
    type?: 'default' | 'small'
}

export default function Loading(props: Props) {
    const type = props.type || 'default';


    return (
        <svg className={`spinner ${type}`} viewBox="0 0 50 50">
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
        </svg>
    )
}
