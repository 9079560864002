import MessagingToken from "./MessagingToken";

export default interface User {
    id: string,
    messagingTokens: MessagingToken[],
}

export const haveSameData = (a: User, b: User): boolean => {
    if (a.id !== b.id) {
        return false;
    }

    if (a.messagingTokens?.length !== b.messagingTokens?.length) {
        return false;
    }

    for (const aToken of a.messagingTokens || []) {
        if (!(b.messagingTokens || []).some(bToken => bToken.token === aToken.token)) {
            console.log('Ha', b.messagingTokens, 'doesnt have', aToken);
            return false;
        }
    }
    return true;
}
