export enum BackendPath {
    INIT_PAYMENT = 'initPayment',
    PAYMENT_CALLBACK = 'paymentCallback',
    GET_SWISH_QR = 'getSwishQR',
    GET_OFFER_TEXT = 'getOfferText',
    REFUND = 'refund',
    PREPARE_RECEIPT_COPY = 'prepareReceiptCopy',
    PHYSICAL_CARD_REGISTER_TERMINAL = 'physicalCardRegisterTerminal',
}

export enum INIT_PAYMENT_ERROR {
    ERROR_PRODUCT_NOT_FOUND = 'product-not-found',
    ERROR_PRICE_MISMATCH = 'price-mismatch',
}