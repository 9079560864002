import ProductOptionChoice, { ProductOptionChoiceForm, productOptionChoiceFromForm, productOptionChoiceToForm } from "./ProductOptionChoice";

export default interface ProductOption {
    name: string,
    mandatory: boolean,
    choices: ProductOptionChoice[],
};

export interface ProductOptionForm {
    name: string,
    mandatory: boolean,
    choices: ProductOptionChoiceForm[],
};


export const productOptionToForm = (productOption: ProductOption): ProductOptionForm => {
    return {
        name: productOption.name,
        mandatory: productOption.mandatory,
        choices: productOption.choices.map(productOptionChoiceToForm),
    };
};

export const productOptionFromForm = (productOption: ProductOptionForm): ProductOption => {
    return {
        name: productOption.name,
        mandatory: productOption.mandatory,
        choices: productOption.choices.map(productOptionChoiceFromForm),
    };
};
