import { CUSTOMER_NAV } from "../../constants/routes";
import { useRestaurant } from "../../contexts/RestaurantContext"
import { useText } from "../../contexts/TextContext";
import RestaurantLink from "../RestaurantLink";


export default function CompanyFooter() {
    const restaurant = useRestaurant();
    const text = useText();
    return (
        <div className={`mt-auto pt-3`}>
            <div className={`comapny-footer d-flex justify-content-around `}>
                <div className="center flex-column">

                    <div>{restaurant.name}</div>
                    {restaurant.email && <small>{restaurant.email}</small>}
                </div>
                <div className="center flex-column">

                    <small>{restaurant.officialName} ({restaurant.orgNum})</small>
                    {restaurant.address && <small>{restaurant.address}</small>}
                </div>
                <div className="center flex-column">

                    <small><RestaurantLink to={`/${CUSTOMER_NAV.TERMS}`}>{text.termsConditions}</RestaurantLink></small>
                    <small><RestaurantLink to={`/${CUSTOMER_NAV.PRIVACY}`}>{text.privacyPolicy}</RestaurantLink></small>

                </div>
            </div>
        </div>
    )
}
