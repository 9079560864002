export function isSameDate(a: Date | null, b: Date | null): boolean {
    if (!a || !b) {
        return false;
    }

    return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
}
export function isSameDateAndTime(a: Date | null, b: Date | null): boolean {
    if (!a || !b) {
        return false;
    }

    if (!isSameDate(a, b)) {
        return false;
    }

    return a.getMinutes() === b.getMinutes() && a.getHours() === b.getHours();
}