import { AppearingText } from './Backstory';
import { onSnapshot, where } from '@firebase/firestore';
import { collection, query } from 'firebase/firestore';
import { useEffect, useState } from 'react'
import { ESTIMATE_TYPE, FEATURE } from '../../constants/features';
import { Collections } from '../../constants/firestore';
import { useDB } from '../../contexts/FirebaseContext';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { useText } from '../../contexts/TextContext'
import { Order, OrderState } from '../../models/Order'
import { getDifferenceInMinutes } from '../../utils/shared/converters';
import { fromFirebaseDocs } from '../../utils/shared/firebase';
import Loading from '../Loading';
import CustomerReceiptForm from './CustomerReceiptForm';
import FeedbackForm from './FeedbackForm';
import OrderNumber from './OrderNumber';

interface Props {
    order: Order
}

export default function ConfirmOrderStatus(props: Props) {
    const text = useText();
    const [inQueue, setInQueue] = useState(0);
    const [, setRerenderEnforcer] = useState(0);
    const restaurant = useRestaurant();
    const { order } = props;
    const db = useDB();

    const usePerOrderEstimate = restaurant.features?.[FEATURE.ESTIMATE_TYPE] === ESTIMATE_TYPE.TIME;

    useEffect(() => {
        if (usePerOrderEstimate) {
            if (order.estimate) {
                const interval = setInterval(() => setRerenderEnforcer(current => current + 1), 5000);
                return () => clearInterval(interval);
            }
        } else if (restaurant.id) {
            const queryObject = query(
                collection(db, `${Collections.restaurants}/${restaurant.id}/${Collections.orders}`),
                where('state', 'in', [OrderState.PAID, OrderState.IN_PROGRESS]),
                where('paid', '<', order.paid));
            return onSnapshot(queryObject, (querySnapshot) => {
                const orders = fromFirebaseDocs(querySnapshot.docs);
                setInQueue(orders.length);
            });
        }
    }, [db, order, restaurant.id, usePerOrderEstimate]);


    const renderPickupTime = () => {
        if (OrderState.DONE === order.state) {
            return <h2>{text.orderReady}</h2>;
        }

        if (usePerOrderEstimate || order.pickupDate) {
            if (!order.estimate && !order.pickupDate) {
                return <>
                    <p>{text.noEstimate}</p>
                    <Loading />
                </>
            }

            const decisionDate = order.pickupDate || order.estimate || new Date();
            const diff = getDifferenceInMinutes(new Date(), decisionDate);

            let diffText = `${diff} ${text.minutes}`;
            if (diff === 1) {
                diffText = `${diff} ${text.minute}`;
            } else if (diff < 1) {
                diffText = `<1 ${text.minute}`;
            }

            return <>
                <p>{text.estimateTime}</p>
                <h4>{diffText}</h4>
            </>
        }

        return <>
            <p>{text.orderInQueue}</p>
            <h3>{inQueue}</h3>
        </>
    }

    return (
        <div className="center flex-column text-primary container">
            <p>{text.orderAccepted}</p>
            <OrderNumber order={order} />
            {renderPickupTime()}

            <FeedbackForm orderId={order.id || ''} />
            <AppearingText className='mt-5 w-100' text={order.thankYouNote} />

            <CustomerReceiptForm order={order} />

            <small className="pt-5">{text.followTheStatus}</small>
        </div>
    )
}
