import { useCart } from '../../contexts/CartContext';
import { useText } from '../../contexts/TextContext';

interface Props {
    className?: string
}


export default function CheckoutTip(props: Props) {
    const text = useText();
    const { cart, updateTip } = useCart();

    const options = [
        { value: 0, label: 'bi-heart' },
        { value: 5, label: 'bi-heart-half' },
        { value: 10, label: 'bi-heart-fill' },
    ]


    return (
        <div className={`center justify-content-start ${props.className}`}>
            <div className='me-2'>{text.tipLabel}</div>
            <div className="btn-group" >
                {options.map((option) => {
                    const active = (cart.tip || 0) === option.value;

                    return <button type="button" className={`btn ${active ? 'btn-secondary' : 'btn-outline-secondary'}`}
                        onClick={() => updateTip(option.value)}
                        disabled={active}
                        key={option.value}>
                        <i className={`bi ${option.label}`} />
                    </button>
                })}
            </div>
        </div >
    )
}
