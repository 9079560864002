import { collection, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import React, { useEffect } from 'react'
import Menu from '../../components/customer/Menu'
import RestaurantLink from '../../components/RestaurantLink';
import { Collections } from '../../constants/firestore';
import { CUSTOMER_NAV } from '../../constants/routes';
import { useAuth } from '../../contexts/AuthContext';
import { useDB } from '../../contexts/FirebaseContext';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { useText } from '../../contexts/TextContext';
import { Order } from '../../models/Order';
import { sumCart } from '../../utils/shared/cart';
import { dateTimeToString, printAmount } from '../../utils/shared/converters';
import { fromFirebaseDocs } from '../../utils/shared/firebase';

const PAGE_SIZE = 10;

export default function MyOrders() {
    const { currentUser } = useAuth();
    const [amount, setAmount] = React.useState(PAGE_SIZE);
    const [orders, setOrders] = React.useState<Order[]>([]);
    const db = useDB();
    const restaurant = useRestaurant();
    const text = useText();

    useEffect(() => {
        const q = query(
            collection(db, `${Collections.restaurants}/${restaurant.id}/${Collections.orders}`),
            where('uid', '==', currentUser?.id),
            orderBy('paid', 'desc'),
            limit(amount)
        );
        return onSnapshot(q, (snapshot) => {
            const orders = fromFirebaseDocs<Order>(snapshot.docs);
            setOrders(orders);
        });
    }, [db, restaurant, amount, currentUser]);

    return (
        <div className='streach'>
            <Menu />
            <div className='container'>
                {orders.map(record => {
                    const { sum } = sumCart(record)

                    return <div className='card p-2 m-2 row flex-row' key={record.id}>
                        <small className='col-4'>{dateTimeToString(record.paid)}</small>
                        <div className='col-4 center'>{printAmount(sum)}</div>
                        <div className='col-4 center justify-content-end' >
                            <RestaurantLink to={`/${CUSTOMER_NAV.MY_ORDERS}/${record.id}`}>
                                <button className='btn btn-outline-secondary'>{text.details}</button>
                            </RestaurantLink>
                        </div>
                    </div>

                })}
                <div className='center mt-3'>
                    <button className='btn btn-outline-secondary'
                        onClick={() => setAmount(amount + PAGE_SIZE)}
                        disabled={orders.length < amount}
                    >{text.loadOlder}</button>
                </div>

            </div>
        </div >
    )
}
