import React from 'react'
import { useAuth } from '../../contexts/AuthContext';
import { useText } from '../../contexts/TextContext'
import useDeals from '../../hooks/useDeals';
import { DealType } from '../../models/Deal';
import Logo from '../Logo';

export default function StampCards() {
    const text = useText();
    const deals = useDeals(DealType.STAMP_CARD)
    const { userDeals } = useAuth();


    if (!deals.length) {
        return <></>
    }

    return (
        <>
            <h2 className='mt-3'>{text.stampCards}</h2>
            {deals.map(deal => {
                const stampSlotCount = (deal.requiredCount || 0) + 1;
                const collected = userDeals.dealTrackers[deal.id]?.stampCount || 0;
                const stampSlots = Array.from({ length: stampSlotCount }, (_, i) => i < collected);

                return <div key={deal.id} className="center my-5">
                    <div className='stamp-card rgb'>
                        <div className='stamp-card-content p-2'>
                            <h5 className=''>{text.collectX.replace('{X}', deal.requiredCount + '')}</h5>

                            <div className='d-flex justify-content-around flex-wrap'>

                                {stampSlots.map((stamped, i) => {
                                    const isLast = i === stampSlots.length - 1;
                                    return <div key={i} className={`stamp center ${isLast ? 'last' : ''}`}>
                                        {stamped && <Logo className='stamp-logo' />}
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>

                </div>
            })}
        </>
    )
}
