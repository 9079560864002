import React, { ReactNode, useCallback, useContext, useState } from "react"
import { Modal } from "react-bootstrap";
import { useText } from "./TextContext";

interface ConfirmOptions {
    text: string
    callback: Function
    skip?: boolean
    hideThisActionCannotBeUndone?: boolean
}
const confirmContext = React.createContext<(options: ConfirmOptions) => void>(() => { });

export function useConfirm() {
    return useContext(confirmContext)
}

interface Props {
    children: ReactNode
}



export default function ConfirmProvider({ children }: Props) {
    const text = useText();
    const [confirmOptions, setConfirmOptions] = useState<ConfirmOptions | null>(null);

    const handleSetConfirmOptions = useCallback((options: ConfirmOptions) => {
        if (options.skip) {
            options.callback();
            return;
        }
        setConfirmOptions(options);
    }, []);


    const handleConfirm = async () => {
        setConfirmOptions(null);
        await confirmOptions?.callback();
    }

    return (
        <confirmContext.Provider value={handleSetConfirmOptions}>
            {children}

            <Modal show={!!confirmOptions} onHide={() => setConfirmOptions(null)} className="modal fade">
                <Modal.Header closeButton>
                    <div className="center ">

                        <div className='center me-2 bg-danger-lighter p-2 rounded-circle center'>
                            <div className='center bg-danger-light p-2 rounded-circle center' style={{ width: '45px' }}>
                                <i className="bi bi-exclamation-circle text-danger h4 m-0" />
                            </div>
                        </div>
                        <Modal.Title className="mb-1">{text.confirmation}</Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <p>{confirmOptions?.text}</p>
                    {!confirmOptions?.hideThisActionCannotBeUndone && <p>{text.thisActionCannotBeUndone}</p>}
                    <div className='mt-5 d-flex'>
                        <button className='btn btn-outline-secondary me-2 flex-1' onClick={() => setConfirmOptions(null)}>{text.cancel}</button>
                        <button className='btn btn-danger flex-1' onClick={handleConfirm} >{text.confirm}</button>
                    </div>
                </Modal.Body>
            </Modal>;
        </confirmContext.Provider>
    )
}