import { doc, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router';
import Menu from '../../components/customer/Menu'
import ProductImg from '../../components/ProductImg';
import RichEditor from '../../components/RichEditor';
import { CUSTOMER_NAV } from '../../constants/routes'
import { useCart } from '../../contexts/CartContext';
import { useDB } from '../../contexts/FirebaseContext';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { useText } from '../../contexts/TextContext';
import Product from '../../models/Product';
import { areAllMandatoryChoosen, productPrice } from '../../utils/shared/cart';
import { printAmount } from '../../utils/shared/converters';
import { fromFirebaseDoc } from '../../utils/shared/firebase';
import { getRestaurantPath } from '../../utils/restaurant';
import { ProductOptionInputMandatory, ProductOptionInputOptional } from '../../components/customer/ProductOptionInput';

export default function ProductDetailPage() {
    const { productId } = useParams<{ productId: string }>();
    const [product, setProduct] = useState<Product | null>(null)
    const [choosen, setChoosen] = useState<string[]>([])
    const [redirect, setRedirect] = useState('');
    const restaurant = useRestaurant();
    const { adjustCount } = useCart();
    const db = useDB();
    const text = useText();
    const back = getRestaurantPath(restaurant, `/${CUSTOMER_NAV.PRODUCTS}`);


    useEffect(() => {
        onSnapshot(doc(db, `restaurants/${restaurant.id}/products/${productId}`), doc => {
            const newProduct = fromFirebaseDoc<Product>(doc);
            setProduct(newProduct)

            const choices = newProduct.options?.map(option => option.choices).flat() || [];
            const defaults = choices
                .filter(choice => !choice.disabled && choice.default)
                .map(choice => choice.id);
            setChoosen(defaults);
        })
    }, [db, productId, restaurant.id]);

    if (!product) {
        return <div />
    }
    if (redirect) {
        return <Navigate to={redirect} />
    }

    const handleAdd = () => {
        adjustCount(+1, product, choosen)
        setRedirect(back);
    }

    const handleChoiceMandatory = (optionIx: number, choiceId: string) => {
        const optionChoices = product.options[optionIx].choices.map(choice => choice.id);
        const newChoosen = choosen
            .filter(choosenId => !optionChoices.includes(choosenId))
            .concat([choiceId]);

        setChoosen(newChoosen);
    }

    const handleChoiceOptional = (choiceId: string, checked: boolean) => {
        const newChoosen = checked ? choosen.concat([choiceId]) : choosen.filter(choice => choice !== choiceId)

        setChoosen(newChoosen);
    }


    const options = (product.options || []).filter(option => !!option.choices?.length);
    const price = productPrice(product, choosen);
    const canAdd = areAllMandatoryChoosen(product, choosen);

    return (
        <div>
            <Menu back={back} />
            <div className="container flex-grow-1 d-flex flex-column text-primary ">
                <div className="d-flex align-items-center">
                    <ProductImg product={product} type="preview" />
                    <div>
                        <h3 className="ms-3">{product.name}</h3>
                        {product.tagline && <h6 className="ms-3">{product.tagline}</h6>}
                    </div>
                </div>

                {options.map((option, optionIx) => option.mandatory
                    ? <ProductOptionInputMandatory key={optionIx} option={option} choosen={choosen} handleChoice={(choiceIx) => handleChoiceMandatory(optionIx, choiceIx)} />
                    : <ProductOptionInputOptional key={optionIx} option={option} choosen={choosen} handleChoice={handleChoiceOptional} />
                )}

                <RichEditor readOnly={true} raw={product.description} />
            </div>
            <div className={`footer-spacer ${canAdd ? 'show' : ''}`}>
                <div className={`center footer ${canAdd ? 'show' : ''} d-flex`}>
                    <button className="btn btn-success flex-grow-1 h-100 center justify-content-between" onClick={handleAdd}>
                        <div><i className="bi bi-cart-plus-fill me-2" /> {text.addToCart}</div>
                        <div>{printAmount(price)}</div>
                    </button>
                </div>
            </div>
        </div>
    )
}
