import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { useFirebaseAuth } from '../../contexts/FirebaseContext';
import { useText } from '../../contexts/TextContext';
import Logo from '../Logo';
import googleLogo from '../../assets/google_logo.png';
import LoginButton from '../login/LoginButton';
import LoginEmailLink from '../login/LoginEmailLink';

interface Props {
    show: boolean;
    onHide: () => void;
}


export default function LoginModal(props: Props) {
    const auth = useFirebaseAuth();
    const text = useText();
    const { currentUser } = useAuth();

    useEffect(() => {
        if (currentUser) {
            props.onHide();
        }
    }, [currentUser, props])


    const handleGoogleAuth = () => {
        signInWithPopup(auth, new GoogleAuthProvider()).catch(err => console.error('auth', err))
    }

    return (
        <Modal show={props.show} onHide={props.onHide} className="modal fade">
            <Modal.Header closeButton>
                <Modal.Title>{text.login}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container center h-100" >
                    <div className='w-100'>
                        <div className="d-flex justify-content-center">
                            <Logo className="logo-login" />
                        </div>

                        <LoginButton handler={handleGoogleAuth} logo={googleLogo} text={text.login_google} />
                        <LoginEmailLink />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
