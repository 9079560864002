import { Firestore } from '@firebase/firestore';
import { useDB } from '../../contexts/FirebaseContext';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { useText } from '../../contexts/TextContext'
import Restaurant from '../../models/Restaurant';
import { dateToUnixSting } from '../../utils/shared/converters';
import { generateSkateverketXMLExport } from '../../utils/exports';
import { CONTROL_UNIT_SERIAL_XML_EXPORT, SYSTEM_VERSION } from '../../constants/kassaregister';
import { AuditLogItem } from '../../models/AuditLogItem';

interface Props {
    from: Date,
    to: Date,
    auditLog: AuditLogItem[],
}

const generateXML = async (db: Firestore, restaurant: Restaurant, from: Date, to: Date, auditLog: AuditLogItem[]) => {
    const kassaName = restaurant.defaultCashRegister || ''

    const xml = generateSkateverketXMLExport({
        startDate: from || new Date(),
        endDate: to || new Date(),
        restaurant,
        kassaRegistry: {
            name: kassaName,
            controlUnitNumber: CONTROL_UNIT_SERIAL_XML_EXPORT,
            manufacturingNumber: SYSTEM_VERSION,
            version: SYSTEM_VERSION
        },
        auditLog,
    });

    return new Blob([xml], { type: 'text/xml' });
}

export default function SkatteverketXMLExport(props: Props) {
    const text = useText();
    const restaurant = useRestaurant();
    const db = useDB();

    const areFiltersCorrect = props.from && props.to;
    const filenameSuffix = areFiltersCorrect ? `${dateToUnixSting(props.from!)}_${dateToUnixSting(props.to!)}` : '';

    const generate = async () => {
        if (!areFiltersCorrect) {
            throw new Error("Filters are wrong");
        }
        const fileName = `${filenameSuffix}.xml`

        const file = await generateXML(db, restaurant, props.from, props.to, props.auditLog);

        const element = document.createElement("a");
        element.href = URL.createObjectURL(file);
        element.download = fileName;
        document.body.appendChild(element);
        element.click();
    }

    return (
        <div className="center flex-column">
            <button className="btn btn-secondary mt-5" onClick={generate} disabled={!areFiltersCorrect}>
                {text.generateSkatteverketExportXML}
            </button>

            {!areFiltersCorrect && <small style={{ width: "12rem", textAlign: 'center' }}>{text.exportFilterRequired}</small>}
        </div >
    )
}
