import { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import { useText } from '../../contexts/TextContext';

interface Props {
    show: boolean;
    onHide: () => void;
}


export default function FinishEmailLoginModal(props: Props) {
    const text = useText();
    const { currentUser, loginWithEmailLink } = useAuth();
    const [email, setEmail] = useState('');
    const emailInput = useRef<HTMLInputElement>(null);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            if (emailInput.current) {
                emailInput.current.focus();
            }
        }, 1000);
    }, [])

    useEffect(() => {
        if (currentUser) {
            props.onHide();
        }
    }, [currentUser, props])


    const verifyEmail = async (e: React.FormEvent) => {
        e.preventDefault();

        setProcessing(true);
        await loginWithEmailLink(email)
    }

    return (
        <Modal show={props.show} onHide={props.onHide} className="modal fade">
            <Modal.Header closeButton>
                <Modal.Title>{text.login}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={verifyEmail}>
                    <div className="container center flex-column w-100 text-center" >
                        <p>{text.login_finishEmailNoEmail1}</p>
                        <p>{text.login_finishEmailNoEmail2}</p>
                        <input autoFocus className="form-control mt-5"
                            ref={emailInput}
                            type='email'
                            placeholder={text.login_email}
                            value={email} onChange={e => setEmail(e.target.value)} />
                        <button className='btn btn-primary px-5 mt-3' disabled={!email.trim() || processing}>
                            {processing && <i className="spinner-grow spinner-grow-sm" />}
                            <span>{text.login}</span>
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}
