import React from 'react'

interface Props {
    text: string
    className?: string
}

export default function NeonText(props: Props) {

    const firstPart = props.text.substring(0, 3);
    const blink1 = props.text.substring(3, 4);
    const secondPart = props.text.substring(4, 7);
    const blink2 = props.text.substring(7, 9);
    const thirdPart = props.text.substring(9);

    return (
        <div className={`center closed-neon ${props.className}`}>
            {firstPart}<span className="blink">{blink1}</span>{secondPart}<span className="blink2 ms-2">{blink2}</span>{thirdPart}
        </div>
    )
}
