import { useParams } from 'react-router-dom';
import DealForm from '../../components/staff/DealForm';
import StaffMenu from '../../components/StaffMenu'
import useDeals from '../../hooks/useDeals';

export default function StaffDealEditPage() {
    const { dealId } = useParams<{ dealId: string }>();
    const deals = useDeals();


    const deal = deals.find(d => d.id === dealId);

    return (
        <div className="h-100 d-flex flex-column">
            <StaffMenu />
            <div className="text-primary container ">

                <DealForm deal={deal} />

            </div>
        </div>
    )
}
