import React, { useState } from 'react'
import { useText } from './TextContext';

interface SaveToast {
    startSaving: () => number
    markSaved: (id?: number) => void
}

interface Props {
    children: any
}

const saveToastContext = React.createContext<SaveToast>({
    startSaving: () => 1,
    markSaved: (id?: number) => { }
});

export const useSaveToast = () => React.useContext(saveToastContext);

let currentSaveId = 0;
const TIMEOUT = 3000;

export default function SaveToastContext({ children }: Props) {
    const text = useText();
    const [currentState, setCurrentState] = useState({ saving: false, saved: false });

    const label = currentState.saving ? text.saving : `${text.saved} ✓`;

    const startSaving = () => {
        setCurrentState({ saving: true, saved: false })
        return ++currentSaveId;
    };

    const markSaved = async (myId?: number) => {
        if (myId && myId !== currentSaveId) {
            return;
        }
        setCurrentState({ saving: false, saved: true })

        await new Promise((resolve) => setTimeout(resolve, TIMEOUT));
        if (myId && myId !== currentSaveId) {
            return;
        }

        setCurrentState({ saving: false, saved: false })
    }

    const isVisible = currentState.saving || currentState.saved;

    return (
        <saveToastContext.Provider value={{ startSaving, markSaved }}>
            <div className={`save-toast ${isVisible ? 'show' : ''}`}>
                <div className={`highlight-color bold save-toast-container`}>
                    {label}
                </div>
            </div>
            {children}
        </saveToastContext.Provider>
    )
}