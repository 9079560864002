import React from 'react'
import { useRestaurant } from '../contexts/RestaurantContext'

export default function TermsPage() {
    const restaurant = useRestaurant();
    let officialName = 'SliceSize AB'
    let name = 'SliceSize'
    if (restaurant.name) {
        officialName = restaurant.officialName || restaurant.name;
        name = restaurant.name;
    }
    const email = restaurant.email || 'Johan.rudby@icloud.com';
    const phone = restaurant.phone || '<phone>';

    return (
        <div className='container text-primary'>
            <h4>Terms and Conditions</h4>
            <p>
                Denna tjänst levereras av {officialName} ({name}), nedan kallade säljare. Nedanstående användarvillkor är en rättslig överenskommelse mellan dig som gäst/kund, aktuellt försäljningsställe varifrån köp genomförs, samt säljare.
            </p>

            <h5>Samtycke</h5>
            <p>Genom att beställa och betala en produkt intygar du att du har läst, förstått och
                samtycker till dessa köpvillkor.</p>

            <h5>Beställning</h5>
            <p>Du väljer dina produkter från säljares tjänst samt utför din betalning.
                Beställningar kan tas emot enligt ordersidans utbud samt öppettider.
                Beställningen uthämtas sedan på försäljningsstället.</p>

            <h5>Beställningsbekräftelse</h5>
            <p>När säljare har tagit emot en betalningsbekräftelse från något av betalsätten
                nedan skapas ett kvitto i din användarsession.</p>

            <h5>Leverans</h5>
            <p>Kvitto måste uppvisas vid leverans av beställning. Ej upphämtad beställning kan
                komma att kastas av försäljningsstället utan att kunden har rätt till
                återbetalning.</p>

            <h5>Betalmetoder</h5>
            <p>Följande betalmetoder kan användas i tjänsten</p>
            <ul>
                <li>Swish</li>
                <li>Kreditkort/kontokort som accepteras av Bambora betalsystem. Bambora
                    ansvarar för att tillämpliga säkerhetskrav för betalsystemen uppfylles, i
                    dagsläget PCI säljare (Payment Card Industry – Data Security Standard). Inga
                    kortuppgifter lagras i säljares tjänst. Kortet debiteras direkt vid köpet.</li>
            </ul>
            <p>Alla priser i tjänsten anges i SEK (Svenska kronor) och är inklusive moms. Moms finns specificerad på kvittot.</p>

            <h5>Klagomål</h5>
            <p>Vid tekniska problem med din beställning, kontakta säljares support på {email} eller {phone}. Alla övriga problem med beställningar hänvisas till aktuellt försäljningsställe, mejl och telefonnummer hittas på kvittot.</p>

            <h5>Återköp</h5>
            <p>Om en beställning återbetalas blir beloppet tillgängligt på ditt kreditkort/kontokort i enlighet med överenskommelsen mellan din bank och kortföretaget. Återbetalning via Swish sker i realtid.</p>

            <h5>Force majeure</h5>
            <p>Säljare ansvarar inte för fel i eller förseningar av några av skyldigheterna enligt
                användarvillkoren om felet eller förseningen beror på orsaker som rimligen ligger
                utanför säljares kontroll. Force majeure innefattar alla åtgärder, händelser,
                uteblivna händelser, fel eller olyckor som ligger utanför säljares kontroll</p>
        </div>
    )
}
