
interface Deal {
    id: string,
    type: DealType,
    usedCount: number,

    discount?: number,
    code?: string,

    requiredCount?: number,
    validProducts?: string[],
};

export default Deal;

export enum DealType {
    WHOLE_CART_PERCENTAGE = 'whole-cart-percentage',
    STAMP_CARD = 'stamp-card',
}

export const DealTypeLabelKeys: Record<DealType, 'percentageCode' | 'stampCard'> = {
    [DealType.WHOLE_CART_PERCENTAGE]: 'percentageCode',
    [DealType.STAMP_CARD]: 'stampCard',
}
