import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { useRestaurant } from '../contexts/RestaurantContext';
import { getRestaurantPath } from '../utils/restaurant';

export default function RestaurantNavLink(props: NavLinkProps) {
    const restaurant = useRestaurant();

    const to = getRestaurantPath(restaurant, props.to + '');

    return <NavLink {...props} to={to} />;
}
