import React, { useEffect, useState } from 'react'
import { convertFromRaw, convertToRaw, EditorState, RawDraftContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './RichEditor.css';

interface Props {
    raw?: RawDraftContentState
    onChange?: (state: RawDraftContentState) => void
    readOnly?: boolean
}

export default function RichEditor(props: Props) {
    const [initialized, setInitialized] = useState(false);
    const [value, setValue] = useState<EditorState>(EditorState.createEmpty());
    useEffect(() => {
        if (props.raw && !initialized) {
            setValue(EditorState.createWithContent(convertFromRaw(props.raw)))
            setInitialized(true);
        }
    }, [props.raw, initialized])


    const handleChange = (state: EditorState) => {
        setValue(state);
        if (props.onChange) {
            props.onChange(convertToRaw(value.getCurrentContent()))
        }
    }

    const toolbar = {
        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link'],
    }
    return (
        <Editor
            wrapperClassName={props.readOnly ? 'readOnly' : ''}
            editorClassName={props.readOnly ? 'readOnly' : ''}
            toolbarClassName={props.readOnly ? 'readOnly' : ''}
            toolbar={toolbar}
            toolbarHidden={props.readOnly}
            editorState={value}
            onEditorStateChange={handleChange}
            readOnly={props.readOnly}
        />
    )
}
