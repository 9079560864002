import React from 'react'
import { useText } from '../../contexts/TextContext'
import { Order } from '../../models/Order';

interface Props {
    order: Order
}

export default function OrderNumber({ order }: Props) {
    const text = useText();

    const orderNumber = (order.publicId || 0) % 100;
    return <div className="alert alert-info center flex-column m-5">
        <small>{text.orderNumber}</small>
        <h3>{orderNumber}</h3>
    </div>
}
