import { PDFDownloadLink } from '@react-pdf/renderer'
import { collection, doc, getDocs, limit, query, setDoc, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Collections } from '../../constants/firestore'
import { useDB } from '../../contexts/FirebaseContext'
import { useRestaurant } from '../../contexts/RestaurantContext'
import { useText } from '../../contexts/TextContext'
import { Order } from '../../models/Order'
import Receipt from '../../models/Receipt'
import { fromFirebaseDocs } from '../../utils/shared/firebase'
import PDFReceipt from '../print/PDFReceipt'

interface Props {
    order: Order
}

export default function CustomerReceiptForm(props: Props) {
    const restaurant = useRestaurant();
    const [receiptPrinted, setReceiptPrinted] = useState(false);
    const { order } = props;
    const db = useDB();
    const text = useText();

    useEffect(() => {
        const q = query(collection(db, `${Collections.restaurants}/${restaurant.id}/${Collections.receipts}`), where('orderId', '==', order.id), limit(1));
        getDocs(q).then((querySnapshot) => {
            const receipts = fromFirebaseDocs<Receipt>(querySnapshot.docs);
            if (receipts.length > 0 && receipts[0].receiptPrintedAt) {
                setReceiptPrinted(true);
            }
        })
    }, [db, restaurant.id, order.id])

    const onReceiptPrint = async () => {
        const orderId = order.id || '';
        const newReceipt: Receipt = {
            orderId,
            receiptPrintedAt: new Date(),
        }
        await setDoc(doc(db, `${Collections.restaurants}/${restaurant.id}/${Collections.receipts}/${orderId}`), newReceipt);
        setReceiptPrinted(true);
    }

    return <PDFDownloadLink document={<PDFReceipt order={order} restaurant={restaurant} copy={false} />}
        fileName={`${restaurant.name}_${order.publicId}.pdf`}>
        {({ loading }) => (loading ? '' : <button className="btn btn-light mt-5"
            disabled={receiptPrinted}
            onClick={onReceiptPrint}>
            <i className="bi bi-receipt me-2" />{receiptPrinted ? text.receiptPrinted : text.receipt}
        </button>)}
    </PDFDownloadLink>
}
