import { addDoc, collection } from 'firebase/firestore';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { Collections } from '../../constants/firestore';
import { useDB } from '../../contexts/FirebaseContext';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { useText } from '../../contexts/TextContext';
import Feedback from '../../models/Feedback';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { DISCORD_LINK } from '../../constants/routes';

interface Props {
    orderId: string;
}

export default function FeedbackForm(props: Props) {
    const [feedback, setFeedback] = React.useState('');
    const [feedbackSent, setFeedbackSent] = useState(false);
    const text = useText();
    const { orderId } = props;
    const db = useDB();
    const restaurant = useRestaurant();


    const submitFeedback = async (e: React.FormEvent) => {
        e.preventDefault();

        const newFeedback: Feedback = {
            text: feedback,
            orderId: orderId,
            created: new Date()
        }
        try {
            await addDoc(collection(db, `${Collections.restaurants}/${restaurant.id}/${Collections.feedback}`), newFeedback);
            toast.success(text.thankYouForInsights);
            setFeedbackSent(true);
        } catch (e) {
            console.log('error feedback', e);
            toast.error(text.errorDefault);
        }

    };

    if (feedbackSent) {
        return <div className='mt-5'>
            <h6>{text.feedbackIsGift}</h6>
            <p>{text.thankYouSoMuch}</p>
            <p>{text.feedbackIfYouWould}</p>
            <div className='center'>
                <a href={DISCORD_LINK} target='_blank' rel='noreferrer' >
                    <button className='btn btn-info'>
                        <i className="bi bi-discord me-2" />Discord
                    </button>
                </a>

            </div>
        </div>
    }

    const feedbackLineCount = feedback.split("\n").length || 1;
    return <div className='mt-5'>
        <OverlayTrigger overlay={<Popover>
            <Popover.Header>{text.feedbackPublic}</Popover.Header>
            <Popover.Body>
                <p>{text.feedbackDiscord}</p>
                <p>{text.feedbackDontHold}</p>
            </Popover.Body>

        </Popover>}>
            <h6>{text.feedbackIsGift} ({text.public} <i className="bi bi-info bg-info rounded-circle" />)</h6>
        </OverlayTrigger>
        <small>{text.feedbackIsGiftDescription}</small>
        <form className='center mt-2' onSubmit={submitFeedback}>
            <textarea className="form-control resize-none"
                rows={feedbackLineCount}
                placeholder={text.iWantYou}
                value={feedback} onChange={e => setFeedback(e.target.value)} />
            <button className='btn' disabled={!feedback.trim()}><i className="bi bi-send-fill text-primary" /></button>
        </form>

    </div>
}
