import React from 'react';
import defaultLogo from '../assets/restaurantLogo.png'
import { useRestaurant } from '../contexts/RestaurantContext';


type Props = {
    className?: string
}

export default function Logo(props: Props) {
    const restaurant = useRestaurant();
    const src = restaurant.logoUrl || defaultLogo;

    return <div><img src={src} className={props.className || 'logo'} alt={restaurant.name} /></div>;
}
