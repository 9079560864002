import { CartItem } from "./Cart";
import Deal from "./Deal";

export interface Order {
    id?: string
    items: CartItem[],
    deals: Deal[],
    cartId: string,
    state: OrderState,
    tip: number,
    uid?: string,

    paymentId?: string,
    paymentInstructionId?: string,
    publicId?: number,

    created: Date,
    updated: Date,
    paid?: Date,
    estimate?: Date,
    pickupDate?: Date | null,

    payerAlias: string
    paymentType?: 'swish' | 'worldline',

    thankYouNote?: string,

    cashRegister?: string,
    refundPublicId?: number,
    refundedAt?: Date,

    controlUnitRecords?: ControlUnitRecords
    workstation?: string
}

export interface ControlUnitRecords {
    receipt?: ControlUnitData
    receiptCopy?: ControlUnitData
    refund?: ControlUnitData
    refundCopy?: ControlUnitData
}

export interface ControlUnitData {
    transaction: string,
    serial: string
}

export enum OrderState {
    NEW = 'new',
    PAID = 'paid',
    FAILED = 'failed',

    IN_PROGRESS = 'inProgress',
    DONE = 'done',
    REFUNDED = 'refunded',

    DELETED = 'deleted',
}

export const ALL_PLACED_ORDER_STATES = [OrderState.PAID, OrderState.IN_PROGRESS, OrderState.DONE, OrderState.REFUNDED];
