import React, { useContext, useState, useEffect } from "react"
import { useDB } from "./FirebaseContext";
import { onSnapshot, query, collection, orderBy } from "firebase/firestore";
import { fromFirebaseDocs } from "../utils/shared/firebase";
import { useText } from "./TextContext";
import { Collections } from "../constants/firestore";
import Product, { ProductState } from "../models/Product";
import { useRestaurant } from "./RestaurantContext";


interface ProductsHolder {
    categories: string[],
    productsByCategory: Record<string, Product[]>
    archivedProducts: Product[]
    allProducts: Product[],
    allProductsById: Record<string, Product>
}

const EMPTY_PRODUCTS: ProductsHolder = {
    categories: [],
    productsByCategory: {},
    archivedProducts: [],
    allProducts: [],
    allProductsById: {}
}

const productsContext = React.createContext<ProductsHolder>(EMPTY_PRODUCTS);

export function useProducts() {
    return useContext(productsContext)
}

export default function ProductsProvider({ children }: any) {
    const restaurant = useRestaurant();
    const [allProducts, setAllProducts] = useState<Product[]>([]);
    const [productsHolder, setProductsHolder] = useState<ProductsHolder>(EMPTY_PRODUCTS);
    const db = useDB();
    const text = useText();
    useEffect(() => {
        const queryObject = query(
            collection(db, Collections.restaurants, restaurant.id, Collections.products),
            orderBy('name'));
        return onSnapshot(queryObject, (querySnapshot) => {
            const newAllProducts = fromFirebaseDocs<Product>(querySnapshot.docs);
            setAllProducts(newAllProducts);

        });
    }, [db, restaurant.id]);

    useEffect(() => {
        if (allProducts.length) {
            const productsByCategory: Record<string, Product[]> = {};
            const allProductsById: Record<string, Product> = {};

            for (const product of allProducts) {
                allProductsById[product.id] = product;

                if (product.state === ProductState.Active) {
                    const category = product.category || text.products;
                    if (!productsByCategory[category]) {
                        productsByCategory[category] = [];
                    }

                    productsByCategory[category].push(product);
                }
            }

            const nonEmptyCategories = Object.keys(productsByCategory);
            const orderedCategories = (restaurant.categoryOrder || [])
                .filter(category => nonEmptyCategories.includes(category));
            const newCategories = nonEmptyCategories
                .filter(category => !orderedCategories.includes(category))
                .sort();
            const categories = orderedCategories.concat(newCategories);

            // sort products in each category
            for (const category of nonEmptyCategories) {
                productsByCategory[category].sort((a, b) => (a.order || 9000) - (b.order || 9000));
            }


            const newProductsHolder: ProductsHolder = {
                categories,
                productsByCategory,
                archivedProducts: allProducts.filter(product => product.state === ProductState.Archived),
                allProducts,
                allProductsById,
            }

            setProductsHolder(newProductsHolder);
        }
    }, [allProducts, restaurant.categoryOrder, text.products])


    return (
        <productsContext.Provider value={productsHolder} >
            {children}
        </productsContext.Provider>
    )
}
