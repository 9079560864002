import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Collections } from "../constants/firestore";
import { useDB } from "../contexts/FirebaseContext";
import { useRestaurant } from "../contexts/RestaurantContext";
import Deal, { DealType } from "../models/Deal";
import { fromFirebaseDocs } from "../utils/shared/firebase";



const useDeals = (dealType?: DealType): Deal[] => {
    const restaurant = useRestaurant();
    const [deals, setDeals] = useState<Deal[]>([]);
    const db = useDB();

    useEffect(() => {
        const colRef = collection(db, Collections.restaurants, restaurant.id, Collections.deals);
        let queryObject = query(colRef);

        if (dealType) {
            queryObject = query(colRef, where('type', '==', dealType));
        }

        return onSnapshot(queryObject, (querySnapshot) => {
            const newDeals = fromFirebaseDocs<Deal>(querySnapshot.docs);
            setDeals(newDeals);

        });
    }, [db, restaurant.id, dealType]);


    return deals;
}

export default useDeals;