import { SYSTEM_NAME } from "../constants/kassaregister";
import { AuditLogDataProductChange, AuditLogItem, AuditLogReceiptData } from "../models/AuditLogItem";
import KassaRegistry from "../models/KassaSystem";
import Restaurant from "../models/Restaurant";
import { DEFAULT_VAT_RATE, sumCart } from "./shared/cart";
import { zeroPad } from "./shared/converters";


interface GenerateSkateverketXMLExportOptions {
    startDate: Date;
    endDate: Date;
    restaurant: Restaurant
    kassaRegistry: KassaRegistry
    auditLog: AuditLogItem[]
}

export const generateSkateverketXMLExport = (options: GenerateSkateverketXMLExportOptions) => {
    const today = formatSkatteverketDate(new Date());

    var xmlDoc = document.implementation.createDocument('http://xmls.skatteverket.se/se/skatteverket/td/instans/utredningsstod/1.0', "utrein:ExportAvDataFranJournalminneEnligtSKVFS2021-16");

    xmlDoc.documentElement.setAttribute('xmlns:xs', 'http://www.w3.org/2001/XMLSchema');
    xmlDoc.documentElement.setAttribute('xmlns:utreko', 'http://xmls.skatteverket.se/se/skatteverket/td/komponent/utredningsstod/1.0');

    const kassaNameNode = xmlDoc.createElement('utreko:BeteckningPaDetKassaregisterSomExportAvDataFranJournalminnetSkerIfran');
    kassaNameNode.textContent = options.kassaRegistry.name;
    xmlDoc.documentElement.appendChild(kassaNameNode);

    const TidpunktForNarExportAvDataFranJournalminnetSker = xmlDoc.createElement('utreko:TidpunktForNarExportAvDataFranJournalminnetSker');
    TidpunktForNarExportAvDataFranJournalminnetSker.textContent = today;
    xmlDoc.documentElement.appendChild(TidpunktForNarExportAvDataFranJournalminnetSker);


    const periodNode = xmlDoc.createElement('utreko:ValdPeriodForExportAvDataFranJournalminne');
    const periodStartNode = xmlDoc.createElement('utreko:ValdStarttidpunktForExportAvDataFranJournalminnet');
    periodStartNode.textContent = formatSkatteverketDate(options.startDate);
    periodNode.appendChild(periodStartNode);

    const periodEndNode = xmlDoc.createElement('utreko:ValdSluttidpunktForExportAvDataFranJournalminnet');
    periodEndNode.textContent = formatSkatteverketDate(options.endDate);
    periodNode.appendChild(periodEndNode);

    xmlDoc.documentElement.appendChild(periodNode);


    const kassaNodes = xmlDoc.createElement('utreko:BeteckningPaValdaKassaregisterForExportAvDataFranJournalminnenLISTA');
    const kassaNode = xmlDoc.createElement('utreko:BeteckningPaValtKassaregisterForExportAvDataFranJournalminnet');
    kassaNode.textContent = options.kassaRegistry.name;
    kassaNodes.appendChild(kassaNode);
    xmlDoc.documentElement.appendChild(kassaNodes);

    const companyNode = xmlDoc.createElement('utreko:InformationOmForetaget');
    const orgNumNode = xmlDoc.createElement('utreko:OrganisationsnummerEllerPersonnummer');
    orgNumNode.textContent = options.restaurant.orgNum;
    companyNode.appendChild(orgNumNode);

    const companyNameNode = xmlDoc.createElement('utreko:ForetagetsNamn');
    companyNameNode.textContent = options.restaurant.officialName;
    companyNode.appendChild(companyNameNode);

    const businessNameNode = xmlDoc.createElement('utreko:NamnPaVerksamheten');
    businessNameNode.textContent = options.restaurant.officialName;
    companyNode.appendChild(businessNameNode);

    const addressNode = xmlDoc.createElement('utreko:DenAdressDarForsaljningSker');
    addressNode.textContent = options.restaurant.address || '';
    companyNode.appendChild(addressNode);
    xmlDoc.documentElement.appendChild(companyNode);




    const kassaRegistries = xmlDoc.createElement('utreko:KassaregistersystemLISTA');

    const kassaRegistry = xmlDoc.createElement('utreko:Kassaregistersystemet');

    const kassaName = xmlDoc.createElement('utreko:TillverkningsnummerForKassaregistret');
    kassaName.textContent = options.kassaRegistry.manufacturingNumber;
    kassaRegistry.appendChild(kassaName);
    const modelNode = xmlDoc.createElement('utreko:ModellbeteckningForKassaregistret');
    modelNode.textContent = SYSTEM_NAME;
    kassaRegistry.appendChild(modelNode);
    const modelElerProgramNode = xmlDoc.createElement('utreko:ModellEllerProgramForKassaregistret');
    modelElerProgramNode.textContent = SYSTEM_NAME;
    kassaRegistry.appendChild(modelElerProgramNode);
    const versionNode = xmlDoc.createElement('utreko:VersionsnummerForKassaregistret');
    versionNode.textContent = options.kassaRegistry.version;
    kassaRegistry.appendChild(versionNode);

    kassaRegistry.appendChild(getKassaNameNode(xmlDoc, options.kassaRegistry));

    const controlUnitManufaturingNumber = xmlDoc.createElement('utreko:TillverkningsnummerForKontrollenheten');
    controlUnitManufaturingNumber.textContent = options.kassaRegistry.controlUnitNumber;
    kassaRegistry.appendChild(controlUnitManufaturingNumber);

    kassaRegistries.appendChild(kassaRegistry);
    xmlDoc.documentElement.appendChild(kassaRegistries);

    const transactionsNode = generateRegistrationLines(xmlDoc, options);

    xmlDoc.documentElement.appendChild(transactionsNode);

    var serializer = new XMLSerializer();
    var xmlString = serializer.serializeToString(xmlDoc);
    const declaration = '<?xml version="1.0" encoding="UTF-8"?>\n';

    return declaration + xmlString;
}

const generateRegistrationLines = (xmlDoc: Document, options: GenerateSkateverketXMLExportOptions) => {
    const transactionsNode = xmlDoc.createElement('utreko:RegistreringLISTA');

    for (const auditLogItem of options.auditLog) {
        let content: HTMLElement;

        if (auditLogItem.type === 'product-change') {
            content = generateProductChamgeHandelse(xmlDoc, auditLogItem);
        } else if (auditLogItem.type === 'receipt-printed') {
            content = generateReceiptPrintedHandelse(xmlDoc, auditLogItem);
        } else {
            continue;
        }

        const node = generateAuditLogHandelse(xmlDoc, options, auditLogItem);
        node.appendChild(content)
        transactionsNode.appendChild(node);
    }


    const otherReportHandelseNode = generateOtherReportHandelse(xmlDoc, options)
    transactionsNode.appendChild(otherReportHandelseNode);

    return transactionsNode;
}

const generateReceiptPrintedHandelse = (xmlDoc: Document, auditLogItem: AuditLogItem) => {
    const data = auditLogItem.data as AuditLogReceiptData;
    const { sum, vat } = sumCart(data.order);

    const receiptNode = xmlDoc.createElement('utreko:Kassakvitto');

    const companyName = xmlDoc.createElement('utreko:ForetagetsNamn');
    companyName.textContent = data.organizationName;
    receiptNode.appendChild(companyName);

    const companyId = xmlDoc.createElement('utreko:OrganisationsnummerEllerPersonnummer');
    companyId.textContent = data.organizationNumber;
    receiptNode.appendChild(companyId);

    const address = xmlDoc.createElement('utreko:DenAdressDarForsaljningSker');
    address.textContent = data.salesAddress;
    receiptNode.appendChild(address);

    const saleDate = xmlDoc.createElement('utreko:DatumOchKlockslagNarKvittoFramstalls');
    saleDate.textContent = formatSkatteverketDate(data.order.paid || new Date());
    receiptNode.appendChild(saleDate);

    const receiptId = xmlDoc.createElement('utreko:LopnummerForKassakvitto');
    receiptId.textContent = data.order.publicId + '';
    receiptNode.appendChild(receiptId);

    const cashRegisterName = xmlDoc.createElement('utreko:Kassabeteckning');
    cashRegisterName.textContent = data.order.cashRegister || ''
    receiptNode.appendChild(cashRegisterName);

    const soldItems = xmlDoc.createElement('utreko:SaldaArtiklarLISTA');

    for (const item of data.order.items) {
        const soldItem = xmlDoc.createElement('utreko:SaldArtikel');

        const typeNode = xmlDoc.createElement('utreko:TypAvArtikel');
        typeNode.textContent = 'VARA';
        soldItem.appendChild(typeNode);

        const idNode = xmlDoc.createElement('utreko:Artikelnummer');
        idNode.textContent = item.itemId;
        soldItem.appendChild(idNode);

        const nameNode = xmlDoc.createElement('utreko:Artikelnamn');
        nameNode.textContent = item.itemName;
        soldItem.appendChild(nameNode);

        const countNode = xmlDoc.createElement('utreko:AntalAvSaldArtikel');
        countNode.textContent = item.quantity + '';
        soldItem.appendChild(countNode);

        const priceNode = xmlDoc.createElement('utreko:PrisPaSaldArtikel');
        priceNode.textContent = item.itemPrice + '';
        soldItem.appendChild(priceNode);

        const someTax = xmlDoc.createElement('utreko:MervardesskattesatsForSaldArtikel');
        someTax.textContent = (item.vatRate || DEFAULT_VAT_RATE) + '';
        soldItem.appendChild(someTax);

        const someOtherTax = xmlDoc.createElement('utreko:MervardesskattPaSaldArtikel');
        someOtherTax.textContent = (item.vatRate || DEFAULT_VAT_RATE) + '';
        soldItem.appendChild(someOtherTax)

        const registrationDate = xmlDoc.createElement('utreko:ArtikelnsRegistreringstidpunkt');
        registrationDate.textContent = formatSkatteverketDate(new Date())
        soldItem.appendChild(registrationDate)


        soldItems.appendChild(soldItem);
    }
    receiptNode.appendChild(soldItems);


    const sumNode = xmlDoc.createElement('utreko:TotaltForsaljningsbeloppISvenskaKronor');
    sumNode.textContent = sum + '';
    receiptNode.appendChild(sumNode)


    const vatNode = xmlDoc.createElement('utreko:MervardesskattPaForsaljningsbeloppet');
    vatNode.textContent = vat + '';
    receiptNode.appendChild(vatNode);


    const amountsPerType = xmlDoc.createElement('utreko:TotalaForsaljningssummorPerBetalningsmedelLISTA');
    const amountPerType = xmlDoc.createElement('utreko:TotalForsaljningssummaPerBetalningsmedel');

    const typeNode = xmlDoc.createElement('utreko:Betalningsmedel');
    typeNode.textContent = data.order.paymentType + ''
    amountPerType.appendChild(typeNode);

    const amountNode = xmlDoc.createElement('utreko:ForsaljningssummaPerBetalningsmedel');
    amountNode.textContent = sum + '';
    amountPerType.appendChild(amountNode)

    amountsPerType.appendChild(amountPerType)
    receiptNode.appendChild(amountsPerType);



    const vatsPerVat = xmlDoc.createElement('utreko:MervardesskattPaOlikaSkattesatserLISTA');
    const vatPerVat = xmlDoc.createElement('utreko:MervardesskattPerSkattesats');

    const vatRateNode = xmlDoc.createElement('utreko:Mervardesskattesats');
    vatRateNode.textContent = DEFAULT_VAT_RATE + '';
    vatPerVat.appendChild(vatRateNode);

    const vatAmountNode = xmlDoc.createElement('utreko:TotaltMervardesskattebeloppPerSkattesats');
    vatAmountNode.textContent = vat + '';
    vatPerVat.appendChild(vatAmountNode)

    vatsPerVat.appendChild(vatPerVat)
    receiptNode.appendChild(vatsPerVat);

    const anotherReceiptId = xmlDoc.createElement('utreko:LopnummerForZDagrapportPaKvitto');
    anotherReceiptId.textContent = data.order.publicId + '';
    receiptNode.appendChild(anotherReceiptId);

    const controlUnitSerial = xmlDoc.createElement('utreko:TillverkningsnummerForKontrollenheten');
    controlUnitSerial.textContent = data.order.controlUnitRecords?.receipt?.serial || '';
    receiptNode.appendChild(controlUnitSerial);

    const controlUnitControlCode = xmlDoc.createElement('utreko:Kontrollkod');
    controlUnitControlCode.textContent = data.order.controlUnitRecords?.receipt?.transaction || '';
    receiptNode.appendChild(controlUnitControlCode);

    return receiptNode;
}

const generateProductChamgeHandelse = (xmlDoc: Document, auditLogItem: AuditLogItem) => {
    const data = auditLogItem.data as AuditLogDataProductChange;

    const productChangeNode = xmlDoc.createElement('utreko:AndraRegistreradArtikel');

    const typeNode = xmlDoc.createElement('utreko:TypAvArtikel');
    typeNode.textContent = 'VARA';
    productChangeNode.appendChild(typeNode);

    const idNode = xmlDoc.createElement('utreko:Artikelnummer');
    idNode.textContent = data.productId;
    productChangeNode.appendChild(idNode);

    const nameNode = xmlDoc.createElement('utreko:Artikelnamn');
    nameNode.textContent = data.product.name;
    productChangeNode.appendChild(nameNode);

    const countNode = xmlDoc.createElement('utreko:AntalAvRegistreradArtikel');
    countNode.textContent = '0';
    productChangeNode.appendChild(countNode);

    const priceNode = xmlDoc.createElement('utreko:PrisPaRegistreradArtikel');
    priceNode.textContent = data.product.basePrice + '';
    productChangeNode.appendChild(priceNode);

    const someTax = xmlDoc.createElement('utreko:MervardesskattesatsForRegistreradArtikel');
    someTax.textContent = (data.product.vatRate || DEFAULT_VAT_RATE) + '';
    productChangeNode.appendChild(someTax);

    const someOtherTax = xmlDoc.createElement('utreko:MervardesskattPaRegistreradArtikel');
    someOtherTax.textContent = (data.product.vatRate || DEFAULT_VAT_RATE) + '';
    productChangeNode.appendChild(someOtherTax);


    const previousCountNode = xmlDoc.createElement('utreko:TidigareAntalAvRegistreradArtikel');
    previousCountNode.textContent = '-1';
    productChangeNode.appendChild(previousCountNode);

    const previousPriceNode = xmlDoc.createElement('utreko:TidigarePrisPaRegistreradArtikel');
    previousPriceNode.textContent = '-1';
    productChangeNode.appendChild(previousPriceNode);

    const previousSomeTax = xmlDoc.createElement('utreko:TidigareMervardesskattesatsForRegistreradArtikel');
    previousSomeTax.textContent = '-1';
    productChangeNode.appendChild(previousSomeTax);

    const previousSomeOtherTax = xmlDoc.createElement('utreko:TidigareMervardesskattPaRegistreradArtikel');
    previousSomeOtherTax.textContent = '-1';
    productChangeNode.appendChild(previousSomeOtherTax);


    const date = xmlDoc.createElement('utreko:UrsprungligRegistreringstidpunkt');
    date.textContent = formatSkatteverketDate(auditLogItem.createdAt);
    productChangeNode.appendChild(date);

    return productChangeNode;
}

const generateOtherReportHandelse = (xmlDoc: Document, options: GenerateSkateverketXMLExportOptions) => {

    const otherReportNode = xmlDoc.createElement('utreko:OvrigRapport');
    const reportName = xmlDoc.createElement('utreko:NamnPaOvrigRapport');
    reportName.textContent = 'Skatteverket XML export';
    otherReportNode.appendChild(reportName);

    const searchParams = xmlDoc.createElement('utreko:Sokparameter');
    searchParams.textContent = formatSkatteverketDate(options.startDate) + ' - ' + formatSkatteverketDate(options.endDate);
    otherReportNode.appendChild(searchParams);

    const handelseNode = generateEmptyHandelse(xmlDoc, options, new Date());
    handelseNode.appendChild(otherReportNode);
    return handelseNode;
}

const generateEmptyHandelse = (xmlDoc: Document, options: GenerateSkateverketXMLExportOptions, date: Date) => {
    const handelseNode = xmlDoc.createElement('utreko:Handelse');
    handelseNode.appendChild(getKassaNameNode(xmlDoc, options.kassaRegistry));
    handelseNode.appendChild(getTimePointNode(xmlDoc, date));
    return handelseNode;
}

const generateAuditLogHandelse = (xmlDoc: Document, options: GenerateSkateverketXMLExportOptions, auditLogItem: AuditLogItem) => {
    const handelseNode = xmlDoc.createElement('utreko:Handelse');
    if (auditLogItem.user) {
        const userNode = xmlDoc.createElement('utreko:InloggadAnvandare');
        userNode.textContent = auditLogItem.user;
        handelseNode.appendChild(userNode);
    }
    handelseNode.appendChild(getKassaNameNode(xmlDoc, options.kassaRegistry));
    handelseNode.appendChild(getTimePointNode(xmlDoc, auditLogItem.createdAt));

    return handelseNode;
}

const getKassaNameNode = (xmlDoc: Document, kassaRegistry: KassaRegistry) => {
    const identificationNode = xmlDoc.createElement('utreko:Kassabeteckning');
    identificationNode.textContent = kassaRegistry.name;
    return identificationNode;
}

const getTimePointNode = (xmlDoc: Document, date: Date) => {
    const timePointNode = xmlDoc.createElement('utreko:Registreringstidpunkt');
    timePointNode.textContent = formatSkatteverketDate(date);
    return timePointNode;
}


const formatSkatteverketDate = (date: Date) => {
    return (
        date.getFullYear() +
        '-' +
        zeroPad(date.getMonth() + 1) +
        '-' +
        zeroPad(date.getDate()) +
        'T' +
        zeroPad(date.getHours()) +
        ':' +
        zeroPad(date.getMinutes()) +
        ':' +
        zeroPad(date.getSeconds())
    );
}