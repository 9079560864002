import React, { FormEvent, useState } from 'react'
import { toast } from 'react-toastify';
import { useCart } from '../../contexts/CartContext';
import { useText } from '../../contexts/TextContext';
import useDeals from '../../hooks/useDeals'
import { DealType } from '../../models/Deal';

interface Props {
    className?: string
}

type inputState = 'closed' | 'open';

export default function CheckoutApplyCode(props: Props) {
    const deals = useDeals();
    const text = useText();
    const [code, setCode] = useState('');
    const { addDeal, removeDeal, cart } = useCart();
    const [state, setState] = useState<inputState>('closed')

    const submit = (e: FormEvent) => {
        e.preventDefault();
        const existingDeal = deals.find(deal => deal.code?.trim().toLowerCase() === code.trim().toLowerCase());

        if (existingDeal) {
            addDeal(existingDeal);
            setCode('');
            toast.success(text.codeApplied);
        } else {
            toast.error(text.codeNotFound);
        }
    }
    const removeCode = () => {
        clearState();
        toast.success(text.successDefault);
    }
    const clearState = () => {
        removeDeal();
        setState('closed')
        setCode('')
    }

    const deal = cart.deals && cart.deals.length && cart.deals[0];

    if (!deals.length) {
        return <></>
    }

    const renderDeal = () => {

        if (deal) {
            if (deal.type === DealType.STAMP_CARD) {
                return <>
                    <div className='center bold'>
                        {cart.deals.map(deal => <div key={deal.id} className="mx-2">
                            {text.collectX.replace('{X}', deal.requiredCount + '')}
                            {(deal.discount || 0) > 1 ? ` (${deal.discount})` : ''}
                        </div>)}
                    </div>
                </>
            }

            return <>
                <div><span className='bold'>{deal.code}</span> - {deal.discount} % </div>
                <button className='btn btn-outline-danger ms-2' onClick={() => removeCode()}>X</button>
            </>
        }

        if (state === 'closed') {
            return <button className='btn btn-secondary' onClick={() => setState('open')}>
                <i className="bi bi-search-heart-fill" />
            </button>
        }

        return <form onSubmit={submit}>
            <div className={`input-group`}>
                <input type='text' className='form-control discount-input'
                    value={code} onChange={e => setCode(e.target.value)} placeholder={text.discountCode} />
                <div className="input-group-append">
                    <button className='btn btn-outline-primary rounded-0'><i className="bi bi-check-lg" /></button>
                    <button
                        onClick={() => clearState()}
                        className='btn btn-outline-secondary rounded-0 rounded-end border-start-0'>
                        <i className="bi bi-x" />
                    </button>
                </div>
            </div>

        </form>
    }

    return (
        <div className={`center justify-content-start ${props.className}`}>
            <div className='me-2'>{text.discount}</div>
            {renderDeal()}
        </div >
    )
}
