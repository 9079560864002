import React from 'react'
import { Page, View, Text, StyleSheet, Document } from '@react-pdf/renderer';
import { Order } from '../../models/Order';
import { useText } from '../../contexts/TextContext';
import { dateTimeToString, printAmount } from '../../utils/shared/converters';
import { sumCart } from '../../utils/shared/cart';
import Restaurant from '../../models/Restaurant';


const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF'
    },
    header: {
        fontSize: 32,
        marginBottom: 20,
        marginTop: 10,
        textAlign: 'center',
        color: 'grey',
    },
    table: {
        margin: 10,
        padding: 10,
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        fontSize: 10,
    },
    sumRow: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginTop: 5,
        marginBottom: 20,
        fontSize: 12,
    },
    quantity: {
        flex: 1
    },
    name: {
        flex: 2
    },
    amount: {
        flex: 2,
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    payedMethod: {
        flexDirection: 'row',
        justifyContent: 'center'
    },
    small: {
        color: 'grey',
        fontSize: 10,
    },
    footnote: {
        flexDirection: 'row',
        justifyContent: 'center',
        color: 'grey',
        fontSize: 10,
    },
    copy: {
        flexDirection: 'row',
        justifyContent: 'center',
        color: 'grey',
        fontSize: 24,
    }
});

interface Props {
    order: Order,
    restaurant: Restaurant,
    copy: boolean,
    returnReceipt?: boolean,
}

export default function PDFReceipt({ order, restaurant, copy, returnReceipt }: Props) {
    const text = useText("swedish");
    const { sum: total, discount, tip, vat, perVatRateVatAmount } = sumCart(order);
    const registerName = order.cashRegister || 'Kassa 1';

    const renderLines = () => {
        const multiplier = returnReceipt ? -1 : 1;

        return <>
            {order.items.map(item => {
                const price = item.quantity * item.itemPrice;
                return <View style={styles.row} key={item.productId}>
                    <View style={styles.quantity}><Text>{item.quantity}</Text></View>
                    <View style={styles.name}><Text>{item.itemName}</Text></View>
                    <View style={styles.amount}><Text>{printAmount(multiplier * price)}</Text></View>
                </View>
            })}

            {!!discount &&
                <View style={styles.sumRow}>
                    <View style={styles.name}><Text>{text.discount}</Text></View>
                    <View style={styles.amount}><Text>{printAmount(multiplier * -1 * discount)}</Text></View>
                </View>
            }
            {!!order?.tip &&
                <View style={styles.sumRow}>
                    <View style={styles.name}><Text>{text.tip}</Text></View>
                    <View style={styles.amount}><Text>{printAmount(multiplier * tip)}</Text></View>
                </View>
            }
            <View style={styles.sumRow}>
                <View style={styles.name}><Text>{text.sum}</Text></View>
                <View style={styles.amount}><Text>{printAmount(multiplier * total)}</Text></View>
            </View>

            {Object.keys(perVatRateVatAmount).map(vatRate => <View style={styles.row} key={vatRate}>
                <Text>{`${text.vat} ${vatRate}%`}</Text>
                <Text>{printAmount(multiplier * perVatRateVatAmount[vatRate as any])}</Text>
            </View>
            )}

            <View style={styles.row}>
                <Text>{`${text.totalVat}`}</Text>
                <Text>{printAmount(multiplier * vat)}</Text>
            </View>
        </>
    }

    const date = (returnReceipt ? order.refundedAt : order.paid) || new Date();
    let controlUnitSerial = order.controlUnitRecords?.receipt?.serial;
    if (returnReceipt) {
        if (copy) {
            controlUnitSerial = order.controlUnitRecords?.refundCopy?.serial;
        } else {
            controlUnitSerial = order.controlUnitRecords?.refund?.serial;
        }
    } else if (copy) {
        controlUnitSerial = order.controlUnitRecords?.receiptCopy?.serial;
    }

    const receiptNumber = returnReceipt ? order.refundPublicId : order.publicId;
    return (
        <Document>
            <Page size="A5" style={styles.page}>
                <View style={styles.header}>
                    <Text>{returnReceipt ? text.returnReceipt : text.receipt}: #{receiptNumber}</Text>
                </View>

                {copy && <View style={styles.copy}>
                    <Text>{`<< ${text.receiptCopy} >>`}</Text>
                </View>}


                <View style={styles.table}>
                    <Text style={styles.small}>{dateTimeToString(date)}</Text>
                    <Text>{restaurant.name}</Text>
                </View>

                <View style={styles.table}>
                    {renderLines()}
                </View>

                {!returnReceipt && <View style={styles.payedMethod}>
                    <Text>{order.paymentType === 'worldline' ? text.paidViaCard : text.paidViaSwish}</Text>
                </View>}


                <View style={{ ...styles.table, marginTop: '20px', fontSize: 10 }}>
                    <Text>{restaurant.officialName}</Text>
                    <Text>{restaurant.officialAddress}</Text>
                    <Text>{restaurant.orgNum}</Text>
                    <Text>{`${text.nameOfRegister}: ${registerName}`}</Text>
                </View>

                {returnReceipt && <View style={styles.footnote}>
                    <Text>{text.receiptReturn5Days}</Text>
                </View>}

                <View style={styles.footnote}>
                    <Text>{text.electronicReceipt}</Text>
                </View>
                {controlUnitSerial && <View style={styles.footnote}>
                    <Text>{controlUnitSerial}</Text>
                </View>}
            </Page>
        </Document>
    )
}
